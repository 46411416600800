import React from "react";

export default function RedPartLogoSVGMobile() {
  return (
    <img
      src="https://www.ignitioncarparts.co.uk/images/logo.png"
      alt="logo"
      style={{ width: "100%", height: "50px" }}
    />
  );
}
