export const navMenuesLeft = [
  "About us",
  "Contact",
  "Store Location",
  "Track Order",
];
export const BASE_URL = "https://site.ignitioncarparts.co.uk/GMApi/";
export const IMAGE_BASE_URL =
  "https://www.ignitioncarparts.co.uk/ProductImage.aspx?img=";
// export const STRIPE_PUBLIC_KEY = "pk_test_eRHDZMm4R0CDLgrU2CTQQjVq";
export const STRIPE_PUBLIC_KEY = "pk_live_LGFWZsxnhDGWNYvsbklPdn4F";
// export const STRIPE_SECRET_KEY = "sk_test_ePjNCHjMPEA9Fcv5J9O6ZSFH";
export const PAYPAL_URL = "https://www.paypal.com/checkoutnow?token=";
export const GLOBAL_USER = "Ignition@#2024@New";
export const DIGEST_US = "11zA13SWE@17l-kk:s/19-dis";

// export default countryNames;
/**
 * petercrago@ignitioncarparts.co.uk	m45320PW2#OSLO
 *
 */
