import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
const initialState = {
  email: "",
  cartProducts: [],
};

const AddtoCartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    initializeCartFromLocalStorage(state) {
      const cartData = JSON.parse(localStorage.getItem("cartData"));
      // const u = JSON.parse(localStorage.getItem("parentUser"));
      if (cartData) {
        state.email = cartData.email || "";
        state.cartProducts = cartData.cartProducts || [];
      }
    },
    addToCart(state, action) {
      const { email, product } = action.payload;
      // console.log(product);
      state.email = email;
      const existingProduct = state.cartProducts.find(
        (p) => p.productcode === product.productcode
      );

      if (existingProduct) existingProduct.quantity += 1;
      else state.cartProducts.push({ ...product, quantity: 1 });

      const updatedCartData = {
        email: state.email,
        cartProducts: state.cartProducts,
      };
      localStorage.setItem("cartData", JSON.stringify(updatedCartData));
      // console.log(product);
      toast.success(
        `Product "${
          product.productCode || product.productcode
        }" added to cart successfully!`
      );
    },
    updateQuantity(state, action) {
      const { productId, quantity } = action.payload;
      const product = state.cartProducts.find(
        (p) => p.productcode === productId
      );

      if (product) {
        product.quantity = quantity;
      }

      const updatedCartData = {
        email: state.email,
        cartProducts: state.cartProducts,
      };
      localStorage.setItem("cartData", JSON.stringify(updatedCartData));
    },
    removeProduct(state, action) {
      const productId = action.payload;
      state.cartProducts = state.cartProducts.filter(
        (p) => p.productcode !== productId
      );

      const updatedCartData = {
        email: state.email,
        cartProducts: state.cartProducts,
      };
      localStorage.setItem("cartData", JSON.stringify(updatedCartData));
    },
    clearCart: (state) => {
      state.cartProducts = [];
      localStorage.removeItem("cartData");
    },
  },
});

export const {
  initializeCartFromLocalStorage,
  addToCart,
  updateQuantity,
  removeProduct,
  clearCart,
} = AddtoCartSlice.actions;

export default AddtoCartSlice.reducer;
