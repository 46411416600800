import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
export default function Dashboard() {
  return (
    <div>
      <Container>
        <Box mt={5} sx={{ bgcolor: "#cfe8fc", height: "auto" }}>
          <div className="row">
            <div className="col-12 col-lg-3 d-flex">
              <Stack gap={3} mt={3} pl={3}>
                <div>
                  <p>Navigation</p>
                </div>
                <div>
                <p></p>
                </div>
              </Stack>
            </div>
            <div className="col-12 col-lg-9 mt-4 mt-lg-0">
              <div>
                <Stack direction={"row"}>
                  <div
                    style={{
                      width: "100%",
                      margin: "15px",
                    }}
                  >
                    sdasd
                  </div>
                  <div
                    style={{
                      width: "100%",
                      margin: "15px",
                    }}
                  >
                    sdasda
                  </div>
                </Stack>
                <div>sda</div>
              </div>
            </div>
          </div>
        </Box>
      </Container>
    </div>
  );
}
