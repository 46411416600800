import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Divider,
  Stack,
  Box,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Container,
  TablePagination,
} from "@mui/material";
import WaveHeader from "../Shared/WaveHeader/WaveHeader";
import { IMAGE_BASE_URL } from "../../Constants/Constants";
import StarRating from "../Shared/Star";
import { useSelector, useDispatch } from "react-redux";
import {
  getOEPartNumber,
  GetProductDetailsView,
  GetProductWithCode,
  resetState,
} from "../../Redux/Reducers/reducerSlices/ProductsSlices/ProductDetailsSlice";
import { useNavigate, useParams } from "react-router-dom";
import "./ProductsView.css";
import PageTitles from "../Shared/PageTitles";
import Breadcrumb from "../Shared/Breadcrumb";
import { addToCart } from "../../Redux/Reducers/reducerSlices/AddToCartSlice/AddtoCartSlice";
const PopoverProductsDetails = () => {
  const [rowsPerPage1, setRowsPerPage1] = useState(5);
  const [rowsPerPage2, setRowsPerPage2] = useState(5);
  const [page1, setPage1] = useState(0);
  const [page2, setPage2] = useState(0);
  const [OESearchQuery, setOESearchQuery] = useState("");
  const [VCSearchQuery, setVCSearchQuery] = useState("");
  const param = useParams();
  // console.log(param);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // console.log(product);
  const {
    productViewDetails,
    productDetailsWithPC,
    OEPartNumber,
    isLoadingOE,
    isLoadingPD,
  } = useSelector((state) => state.ProductDetails);
  const userData = useSelector((state) => state.login.data[0]);

  const email = userData?.email || "";

  // console.log(productDetailsWithPC);
  const handleViewDetil = () => {
    dispatch(
      GetProductDetailsView(
        productDetailsWithPC?.productcode || productDetailsWithPC?.productCode
      )
    );
    setVCSearchQuery("");
  };
  // console.log(productViewDetails);
  useEffect(() => {
    const pc = param?.productId;
    pc !== undefined &&
      dispatch(
        GetProductWithCode({
          SearchInput: pc,
          searchType: "PC",
          userID: userData?.id || null,
        })
      );
  }, [param]);
  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, []);
  const handleChangePage1 = (event, newPage) => {
    setPage1(newPage);
  };
  const handleChangePage2 = (event, newPage) => {
    setPage2(newPage);
  };

  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage1(+event.target.value);
    setPage1(0);
  };
  const handleChangeRowsPerPage2 = (event) => {
    setRowsPerPage2(+event.target.value);
    setPage2(0);
  };
  const handleOEPartNumber = () => {
    dispatch(
      getOEPartNumber({
        PC:
          productDetailsWithPC?.productcode ||
          productDetailsWithPC?.productCode,
      })
    );
    setOESearchQuery("");
  };
  const addToCartFun = (e) => {
    dispatch(addToCart({ email, product: productDetailsWithPC }));
  };
  const filteredDataOE = OEPartNumber?.filter(
    (order) =>
      order?.oe?.toLowerCase()?.includes(OESearchQuery?.toLowerCase()) ||
      order?.manufacturer?.toLowerCase()?.includes(OESearchQuery?.toLowerCase())
  );
  const filteredDataVC = productViewDetails?.filter(
    (order) =>
      order?.cc?.toLowerCase()?.includes(VCSearchQuery?.toLowerCase()) ||
      order?.engineCode
        ?.toLowerCase()
        ?.includes(VCSearchQuery?.toLowerCase()) ||
      order?.vehicle?.toLowerCase()?.includes(VCSearchQuery?.toLowerCase()) ||
      order?.yearFrom?.toLowerCase()?.includes(VCSearchQuery?.toLowerCase()) ||
      order?.yearTo?.toLowerCase()?.includes(VCSearchQuery?.toLowerCase())
  );
  return (
    <Box
      sx={{
        background: "#fafafa",
        padding: {
          xl: "12px 5em ",
          lg: "12px 5em ",
          sm: "12px 3em ",
          xs: "12px 1em ",
        },
      }}
    >
      <Stack direction={"row"} mb={1.5}>
        <Breadcrumb text={"Home"} />
        <Breadcrumb text={"Product Details"} />
      </Stack>
      <WaveHeader title={"Product Details"} />

      <div className="pd-wrap">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              {/* Image section */}
              <div className="product-images">
                {/* Display images here */}
                <img
                  src={IMAGE_BASE_URL + productDetailsWithPC?.pictureName}
                  alt="Product"
                  className="product-popup-image-style"
                />
                {/* Add more images if needed */}
              </div>
            </div>
            <div className="col-md-6">
              {/* Product details section */}
              <div className="product-dtl">
                <div className="product-info">
                  {/* <div className="heading-section">
                    {productDetailsWithPC?.partManufacturer || "-"}
                  </div> */}
                  <Stack
                    direction={"row"}
                    spacing={2}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <StarRating rating={3} />
                    <Typography
                      textAlign={"center"}
                      variant="body2"
                      color="text.secondary"
                      fontSize={"12px"}
                    >
                      5 on 66 reviews
                    </Typography>
                  </Stack>
                  <div className="product-name">
                    {productDetailsWithPC?.productcode ||
                      productDetailsWithPC?.productCode}
                  </div>
                  {/* <div className="product-manufacturer">
                    Manufacturer: {productDetailsWithPC?.partManufacturer}
                  </div> */}
                  {/* <div className="product-model">
                    Model: {productDetailsWithPC?.model}
                  </div> */}
                  {/* <div className="product-condition">
                    Condition: {productDetailsWithPC?.partCondition}
                  </div> */}
                  <div className="product-details">
                    <table>
                      <tbody>
                        <tr>
                          <th width="150px">Product Code</th>
                          <td>
                            {productDetailsWithPC.productcode ||
                              productDetailsWithPC.productCode}
                          </td>
                        </tr>
                        <tr>
                          <th>Manufacturer</th>
                          <td>{productDetailsWithPC.manufacturer}</td>
                        </tr>
                        <tr>
                          <th>Title</th>
                          <td>{productDetailsWithPC.title}</td>
                        </tr>
                        <tr>
                          <th>Type</th>
                          <td>{productDetailsWithPC.type}</td>
                        </tr>
                        <tr>
                          <th>Condition</th>
                          <td>{productDetailsWithPC.partCondition}</td>
                        </tr>
                        <tr>
                          <th>Warranty</th>
                          <td>{productDetailsWithPC.warranty}</td>
                        </tr>
                        <tr>
                          <th>Stock</th>
                          <td>{productDetailsWithPC.stockText}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="product-price-discount">
                    Price: <span>{productDetailsWithPC?.retailPrice}</span>
                    {/* <span className="line-through">$29.00</span> this element can be used for showing discount and original price */}
                  </div>
                  <div className="product-stock">
                    Stock: {productDetailsWithPC?.stockText}
                  </div>
                </div>
                {/* <p>{productDetailsWithPC?.notes}</p>
                 */}
                <p>
                  <b>Note: </b>
                  {productDetailsWithPC.notes1}
                </p>
                {/* <div className="product-count"> */}
                {/* <Stack direction={'row'} spacing={3} alignItems={'center'}> */}
                {/* <label htmlFor="quantity">Quantity</label>
                <input
                  type="number"
                  id="quantity"
                  name="quantity"
                  defaultValue="1"
                  min="1"
                /> */}
                <Stack
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  direction={"row"}
                  mt={2}
                >
                  <button
                    className="round-black-btn"
                    onClick={() => handleViewDetil()}
                  >
                    {isLoadingPD ? "Loading..." : " Vehicle Compatibility"}
                  </button>
                  <button
                    className="round-black-btn"
                    onClick={() => handleOEPartNumber()}
                  >
                    {isLoadingOE ? "Loading..." : "Original OE Part Numbers"}
                  </button>
                </Stack>
                <button className="add-to-cart-btn" onClick={addToCartFun}>
                  Add to cart
                </button>

                {/* </Stack> */}
                {/* </div> */}
              </div>
            </div>
          </div>
          <div className="product-info-tabs">
            {/* Tab navigation */}
            {/* Tab content */}
          </div>
        </div>
      </div>

      {productViewDetails?.length ? (
        <Container maxWidth={"lg"}>
          <PageTitles title={"Vehicle Compatibility"} />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            m={1}
          >
            <input
              type="text"
              placeholder="Search"
              value={VCSearchQuery}
              onChange={(e) => setVCSearchQuery(e.target.value)}
              style={{
                padding: "10px",
                fontSize: "16px",
                width: "100%",
                maxWidth: "400px",
                marginBottom: "20px",
              }}
            />
          </Box>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead
                  sx={{
                    backgroundColor: "#333333",
                  }}
                >
                  <TableRow>
                    <TableCell align="left">Engine Code</TableCell>
                    <TableCell align="left">CC</TableCell>
                    <TableCell align="left">Vahicle</TableCell>
                    <TableCell align="left">Year From</TableCell>
                    <TableCell align="left">Year To</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  style={{
                    zIndex: "-1",
                  }}
                >
                  {productViewDetails?.length
                    ? filteredDataVC
                        .slice(
                          page1 * rowsPerPage1,
                          page1 * rowsPerPage1 + rowsPerPage1
                        )
                        .map((row, i) => (
                          <TableRow
                            key={i}
                            // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell align="left">{row.engineCode}</TableCell>
                            <TableCell align="left">{row.cc}</TableCell>
                            <TableCell align="left">{row.vehicle}</TableCell>
                            <TableCell align="left">{row.yearFrom}</TableCell>
                            <TableCell align="left">{row.yearTo}</TableCell>
                          </TableRow>
                        ))
                    : ""}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={filteredDataVC?.length}
              rowsPerPage={rowsPerPage1}
              page={page1}
              onPageChange={handleChangePage1}
              onRowsPerPageChange={handleChangeRowsPerPage1}
            />
          </Paper>
        </Container>
      ) : (
        ""
      )}

      {OEPartNumber?.length ? (
        <Container maxWidth={"lg"} sx={{ marginTop: "2em" }}>
          <PageTitles title={"Original OE Part Numbers"} />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            m={1}
          >
            <input
              type="text"
              placeholder="Search"
              value={OESearchQuery}
              onChange={(e) => setOESearchQuery(e.target.value)}
              style={{
                padding: "10px",
                fontSize: "16px",
                width: "100%",
                maxWidth: "400px",
                marginBottom: "20px",
              }}
            />
          </Box>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead
                  sx={{
                    backgroundColor: "#333333",
                  }}
                >
                  <TableRow>
                    <TableCell align="center">Manufacturer</TableCell>
                    <TableCell align="center">OE</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  style={{
                    zIndex: "-1",
                  }}
                >
                  {OEPartNumber?.length
                    ? filteredDataOE
                        .slice(
                          page2 * rowsPerPage2,
                          page2 * rowsPerPage2 + rowsPerPage2
                        )
                        .map((row, i) => (
                          <TableRow
                            key={i}
                            // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell width={"50%"} align="center">
                              {row.manufacturer}
                            </TableCell>
                            <TableCell width={"50%"} align="center">
                              {row.oe}
                            </TableCell>
                          </TableRow>
                        ))
                    : ""}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={filteredDataOE.length}
              rowsPerPage={rowsPerPage2}
              page={page2}
              onPageChange={handleChangePage2}
              onRowsPerPageChange={handleChangeRowsPerPage2}
            />
          </Paper>
        </Container>
      ) : (
        ""
      )}
    </Box>
  );
};

export default PopoverProductsDetails;
