import React, { useEffect, useState } from "react";

const StarRating = ({ rating }) => {
    const [filledStars, setFilledStars] = useState(0);

    useEffect(() => {
        // Calculate the number of filled stars based on the rating
        const filled = Math.floor(rating);
        const remainder = rating - filled;
        setFilledStars(filled + (remainder >= 0.5 ? 1 : 0));
    }, [rating]);

    return (
        <div className="star-rating">
            {[1, 2, 3, 4, 5].map((value) => (
                <span
                    key={value}
                    className={value <= filledStars ? 'star filled' : 'star'}
                >
                    &#9733;
                </span>
            ))}
        </div>
    );
};

export default StarRating;
