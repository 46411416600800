import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import "./StripePaymentGateway.css";

import { BASE_URL, STRIPE_PUBLIC_KEY } from "../../../Constants/Constants";
import { useDispatch, useSelector } from "react-redux";
import { SetstripPI_ID } from "../../../Redux/Reducers/reducerSlices/ChechOutSlice/OrderSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

export default function StripeCheckout() {
  const navigate = useNavigate();
  const [clientSecret, setClientSecret] = useState("");
  const { checkoutProducts, totalAmount, orderID } = useSelector(
    (state) => state.order
  );
  const userData = useSelector((state) => state.login.data[0]);

  console.log(userData);

  // const price = localStorage.getItem("FP");
  const dispatch = useDispatch();

  useEffect(() => {
    if (totalAmount === undefined || clientSecret === undefined) {
      navigate("/CartDisplay");
    }
    const handleBeforeUnload = (event) => {
      event.preventDefault();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [totalAmount, clientSecret]);
  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch(BASE_URL + "create-intent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify({ totalAmount: currentOrder.totalAmount, orderId:currentOrder.id }),
      body: JSON.stringify({
        // token: token.id,
        token: "tok_visa",
        amount: totalAmount * 100, // example amount in cents ($50.00)
        // amount: (totalAmount || price) * 100, // example amount in cents ($50.00)
        currency: "gbp",
        description: `Order ID-${orderID}--Customer Name-${userData?.firstName}${userData?.lastName}`,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setClientSecret(data.clientSecret);
        dispatch(SetstripPI_ID(data.paymentIntentId));
      });
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="Stripe">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  );
}
