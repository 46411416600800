import React, { useEffect, useState } from "react";
import {
  Grid,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Stack,
  CircularProgress,
} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Pagination from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import ProductCard from "./ProductCard";
import "./ProductsView.css";
import Breadcrumb from "../Shared/Breadcrumb";
import { ColorButtonGrey } from "../../Themes/Theme";
import TuneTwoToneIcon from "@mui/icons-material/TuneTwoTone";
import Loader from "../Shared/Loader";
import productNotFound from "../../svg/undraw_No_data_re_kwbl.png";
import PageTitles from "../Shared/PageTitles";
import { toast } from "react-toastify";
import {
  GetPartType,
  GetProductDetails,
  setdataCleanUp,
} from "../../Redux/Reducers/reducerSlices/ProductsSlices/ProductDetailsSlice";
const ProductView = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [sortBy, setSortBy] = useState("default");
  const [productsData, SetProductData] = useState([]);
  const [pageSize, setPageSize] = useState(12);

  const dispatch = useDispatch();

  const pData = useSelector((state) => state.ProductDetails?.data || []);
  const userData = useSelector((state) => state.login.data[0]);
  // console.log(productsData, "product state data");
  // console.log(pData, "product data");
  const {
    selectedMfg,
    PartTypeByManufacturer,
    isLoadingForDrawer,
    vehicalFinderObj,
  } = useSelector((state) => state.ProductDetails);
  const isLoading = useSelector((state) => state.ProductDetails.isLoading);
  useEffect(() => {
    if (pData?.length) {
      SetProductData(pData);
    }
  }, [pData]);
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };
  const handleFilterDrawerOpen = () => {
    if (productsData.length) {
      setFilterDrawerOpen(true);
      dispatch(GetPartType({ SearchInput: selectedMfg }));
    } else if (selectedMfg.length === 0) {
      toast.error("Please select a Manufacturer.");
    } else {
      toast.error(`No Data for Manufacturer ${selectedMfg}`);
    }
  };

  const handleFilterDrawerClose = () => {
    setFilterDrawerOpen(false);
  };

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
  };

  const sortProducts = (products, sortBy) => {
    if (!products) {
      console.error("Products is null or undefined.");
      return [];
    }

    return (
      productsData.length &&
      [...products].sort((a, b) => {
        const priceA = parseFloat(a?.retailPrice?.replace(/[^\d.]/g, ""));
        const priceB = parseFloat(b?.retailPrice?.replace(/[^\d.]/g, ""));

        if (sortBy === "priceLowToHigh") {
          return priceA - priceB;
        } else if (sortBy === "priceHighToLow") {
          return priceB - priceA;
        } else if (sortBy === "default") {
          return products;
        }
        return 0;
      })
    );
  };
  const sortedProducts =
    productsData?.length && sortProducts(productsData, sortBy);

  const pageCount =
    productsData?.length &&
    productsData !== null &&
    Math.ceil(sortedProducts.length / pageSize);
  const displayedProducts =
    productsData?.length &&
    productsData !== null &&
    sortedProducts?.slice(currentPage * pageSize, (currentPage + 1) * pageSize);
  const handleMFGPartSearch = (pn) => {
    SetProductData([]);
    dispatch(setdataCleanUp());
    const { manufacturer, model, year, series, cc, fuelType, bodyType } =
      vehicalFinderObj;
    let fullPara = `${selectedMfg}#${pn}#${manufacturer || null}#${
      model || null
    }#${year || null}#${series || null}#${cc || null}#${fuelType || null}#${
      bodyType || null
    }`;
    dispatch(
      GetProductDetails({
        SearchInput: fullPara,
        type: 6,
        userID: userData?.id || null,
      })
    );
    setFilterDrawerOpen(false);
  };
  const handlePageCount = (e) => {
    setPageSize(parseInt(e.target.value));
  };
  // console.log(currentPage);
  return (
    <div className="product-view-body">
      {isLoading ? (
        <Loader />
      ) : (
        <Container maxWidth="xl">
          <Stack direction={"row"}>
            <Breadcrumb text={"Home"} />
            <Breadcrumb text={"Product List"} />
          </Stack>
          <PageTitles title={"Product View"} />
          {/* Filter section */}
          {!productsData ? (
            <div className="Product-view-title">No Results Found :(</div>
          ) : (
            <>
              <div className="product-view-filter-section row">
                <div className="col-lg-6 col-sm-12 product-view-filter-section-wapper-1">
                  <ColorButtonGrey
                    className="product-view-filter-button"
                    onClick={handleFilterDrawerOpen}
                  >
                    <TuneTwoToneIcon fontSize="small" /> Filters
                  </ColorButtonGrey>
                  <div className="product-view-page-count-text">
                    Showing{" "}
                    {currentPage === 0 ||
                    currentPage === null ||
                    currentPage === undefined
                      ? pageSize
                      : `${pageSize * currentPage + " "}`}{" "}
                    out of {sortedProducts.length}
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 product-view-filter-section-wapper-2">
                  {/* Sorting dropdown */}
                  <div>
                    <span style={{ marginRight: "5px" }}>Sort:</span>
                    <select
                      className="product-view-filter-dropdown"
                      value={sortBy}
                      onChange={handleSortChange}
                    >
                      <option value="default">Default</option>
                      <option value="priceLowToHigh">Price: Low - High</option>
                      <option value="priceHighToLow">Price: High - Low</option>
                    </select>
                  </div>
                  {/* Number of items per page dropdown */}
                  <div>
                    <span style={{ marginRight: "5px" }}>Show:</span>
                    <select
                      onChange={(e) => handlePageCount(e)}
                      className="product-view-filter-dropdown"
                    >
                      <option value={12}>12</option>
                      <option value={32}>32</option>
                    </select>
                  </div>
                </div>
              </div>

              {/* Products grid */}
              <Box
                mt={2}
                sx={{
                  height: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Grid container spacing={3}>
                  {productsData.length ? (
                    displayedProducts.map((product, i) => (
                      <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
                        <ProductCard key={i} product={product} />
                      </Grid>
                    ))
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <img
                        alt="porduct not found"
                        src={productNotFound}
                        style={{
                          height: "200px",
                          width: "200px",
                          objectFit: "contain",
                        }}
                      />
                      <div className="Product-view-title">
                        Please search for product
                      </div>
                    </div>
                  )}
                </Grid>
                {/* Pagination */}
                <Pagination
                  pageCount={pageCount}
                  pageRangeDisplayed={1}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageChange}
                  containerClassName="pagination"
                  activeClassName="active"
                  previousLabel="<"
                  nextLabel=">"
                />
                {/* Filter drawer */}
                <Drawer
                  anchor="left"
                  open={filterDrawerOpen}
                  onClose={handleFilterDrawerClose}
                >
                  {isLoadingForDrawer ? (
                    <Box
                      sx={{
                        width: 450,
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress color={"error"} />
                    </Box>
                  ) : selectedMfg.length && productsData.length ? (
                    <List
                      sx={{
                        width: 450,
                      }}
                    >
                      <Box
                        sx={{
                          height: "50px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          // border: "3px solid #e52727",
                          borderRadius: "8px",
                          margin: 2,
                          background: "#ffeb3b",
                        }}
                      >
                        <div className="drawer-part-title">{selectedMfg}</div>
                      </Box>
                      <Box
                        mx={2}
                        boxShadow={"0 1px 3px rgba(65, 64, 64, 0.212)"}
                      >
                        {PartTypeByManufacturer &&
                          PartTypeByManufacturer.map((m) => (
                            <ListItem>
                              <ListItemText
                                sx={{
                                  borderBottom: "1px solid #e1e1e1",
                                  mt: 0,
                                  pb: "15px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleMFGPartSearch(m?.type);
                                }}
                                primary={`${m.type}`}
                              />
                            </ListItem>
                          ))}
                      </Box>
                    </List>
                  ) : (
                    <List
                      sx={{
                        width: 450,
                      }}
                    >
                      <Box
                        sx={{
                          height: "50px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          // border: "3px solid #e52727",
                          borderRadius: "8px",
                          margin: 2,
                          background: "#e52727",
                        }}
                      >
                        <div
                          style={{
                            color: "#fff",
                          }}
                          className="drawer-part-title"
                        >
                          {`No data for ${selectedMfg}`}
                        </div>
                      </Box>
                    </List>
                  )}
                </Drawer>
              </Box>
            </>
          )}
        </Container>
      )}
    </div>
  );
};
export default ProductView;
