import React from "react";
import { Stack } from "@mui/material";
import "./HeaderMenu.css";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
export default function HeaderMenu() {
  const userData = useSelector((state) => state.login.data[0]) || [];
  const navigate = useNavigate();
  React.useEffect(() => {
    // console.log("calling  header menu");
  }, [userData]);
  return (
    <div className="top-menu-sub-menu">
      <div className="top-menu-sub-menu-wrapper">
        <div className={"top-menu-sub-menu-left"} onClick={() => navigate("/")}>
          <svg
            fill="#fff"
            xmlns="http://www.w3.org/2000/svg"
            width="16px"
            height="12px"
          >
            <path d="M0,7L0,5L16,5L16,7L0,7ZM0,0L16,0L16,2L0,2L0,0ZM12,12L0,12L0,10L12,10L12,12Z" />
          </svg>

          <div className="top-menu-sub-menu-left-text">Vehicle Finder</div>
        </div>
        <div className="top-menu-border">
          <div className="top-menu-sub-menu-right">
            <Stack direction={"row"} spacing={1}>
              <div
                className="top-menu-sub-menu-right-text"
                onClick={() => navigate("/")}
              >
                Home
              </div>
              <div
                className="top-menu-sub-menu-right-text"
                onClick={() => navigate("/AboutUs")}
              >
                About Us
              </div>
              <div
                className="top-menu-sub-menu-right-text"
                onClick={() => navigate("/ContactUs")}
              >
                Contact Us
              </div>
              {userData.length !== 0 ? (
                <div
                  className="top-menu-sub-menu-right-text"
                  onClick={() => {
                    navigate("/UpdateUser");
                  }}
                >
                  My Profile
                </div>
              ) : (
                ""
              )}
              {/* <div className="top-menu-sub-menu-right-text">Dashboard</div> for admin side */}
              {userData.length !== 0 ? (
                <div
                  className="top-menu-sub-menu-right-text"
                  onClick={() => {
                    navigate("MyOrders");
                  }}
                >
                  My Orders
                </div>
              ) : (
                ""
              )}
              {userData?.isAdmin ? (
                <div
                  className="top-menu-sub-menu-right-text"
                  onClick={() => {
                    navigate("Admin");
                  }}
                >
                  Login as user
                </div>
              ) : (
                ""
              )}
              {/* <div
                className="top-menu-sub-menu-right-text"
                onClick={() => navigate("/ProductView")}
              >
                Products
              </div> */}
            </Stack>
          </div>
        </div>
      </div>
    </div>
  );
}
