import React from "react";
import { styled } from "@mui/system";

const SkewedBreadcrumb = styled("div")({
  backgroundColor: "#ebebeb",
  padding: "2px 10px", // Adjust padding as needed
  display: "inline-block",
  transform: "skewX(-15deg)", // Skew transformation
  color: "#6c757d",
  fontSize: "14px",
  margin: "5px 2px",
  borderRadius:'4px'
});

const NormalText = styled("span")({
  transform: "skewX(15deg)", // Reverse skew transformation for text
  display: "inline-block",
});
const Breadcrumb = ({ text }) => {
  return (
    <SkewedBreadcrumb>
      <NormalText>{text}</NormalText>
    </SkewedBreadcrumb>
  );
};

export default Breadcrumb;
