import React, { useEffect } from "react";
import NavBarDesktop from "../Components/Header/NavBar/NavBar-Desktop/NavBarDesktop";
import HeaderMenu from "../Components/Header/HeaderMenu/HeaderMenu";
import { Route, Routes } from "react-router-dom";
import HomePageContentDesktop from "../Components/Home/HomePageContent/HomePageContentDesktop";
import Dashboard from "../Components/Dashboard/Dashboard";
import ProductView from "../Components/Products/ProductsView";
import CartDisplay from "../Components/Products/Cart/CartDisplay";
import StripPaymentGateway from "../Components/PaymentGateway/Stripe/StripPaymentGateway";
import PayPalPaymentGateway from "../Components/PaymentGateway/PayPal/PayPalPaymentGateway";
import PaymentSuccess from "../Components/PaymentGateway/PaymentSuccess.jsx/PaymentSuccess";
import MyOrders from "../Components/Header/MyOrders/MyOrders";
import RegisterForm from "../Components/Account/Register/RegisterForm";
import Footer from "../Components/Footer/Footer";
import AboutUs from "../Components/Header/HeaderMenu/AboutUs/AboutUs";
import { LocalStorageLogin } from "../Redux/Reducers/reducerSlices/LoginSlices/LoginSlice";
import { useDispatch } from "react-redux";
import ContactUs from "../Components/Header/HeaderMenu/ContactUs/ContactUs";
import PopoverProductsDetails from "../Components/Products/PopoverProductsDetails";
import UpdateUser from "../Components/Account/UpdateUser/UpdateUser";
import PayPalConfirmation from "../Components/PaymentGateway/PayPal/PayPalConfirmation";
import AdminLogin from "../Components/Admin/AdminLogin/AdminLogin";
export default function RoutesComponent() {
  // const userData = useSelector((state) => state.login.data[0]);
  const dispatch = useDispatch();
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userData"));
    if (user) {
      dispatch(LocalStorageLogin(user));
    }
  }, [dispatch]);
  return (
    <div>
      <NavBarDesktop />
      <HeaderMenu />
      <Routes>
        <Route path="/" exact element={<HomePageContentDesktop />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/ProductView" element={<ProductView />} />
        <Route path="/CartDisplay" element={<CartDisplay />} />
        <Route path="/StripGateWay" element={<StripPaymentGateway />} />
        <Route path="/paypalGateWay" element={<PayPalPaymentGateway />} />
        <Route path="/PaymentSuccess" element={<PaymentSuccess />} />
        <Route path="/MyOrders" element={<MyOrders />} />
        <Route path="/Register" element={<RegisterForm />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/UpdateUser" element={<UpdateUser />} />
        <Route
          path="/PopoverProductsDetails/:productId"
          element={<PopoverProductsDetails />}
        />
        <Route
          path="/PayPalConfirmation/:PPkey"
          element={<PayPalConfirmation />}
        />
        <Route path="/Admin" element={<AdminLogin />} />
      </Routes>

      {/* Protected routes */}
      <Footer />
    </div>
  );
}
