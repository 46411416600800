import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../../Constants/Constants";
import { toast } from "react-toastify";
const initialState = {
  loading: false,
  error: null,
  checkoutProducts: [],
  stripPI_ID: "",
  totalAmount: "",
  orderID: "",
  PaypalPaymentStatus: "",
};

export const sendOrder = createAsyncThunk(
  "orders/sendOrder",
  async (mappedProducts) => {
    try {
      const response = await axios.post(
        `${BASE_URL}InsertUpdateOrders`,
        mappedProducts
      );
      // console.log(mappedProducts);
      return response.data.value;
    } catch (error) {
      throw Error(
        error.response?.data?.message || "Error sending order to API"
      );
    }
  }
);
export const paypalStatus = createAsyncThunk(
  "orders/paypalStatus",
  async (token) => {
    // console.log(token);
    try {
      const response = await axios.post(`${BASE_URL}CaptureOrder/${token}`);
      // console.log(mappedProducts);
      return response.data;
    } catch (error) {
      throw Error(
        error.response?.data?.message || "Error sending order to API"
      );
    }
  }
);
const OrderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    checkOutProductData: (state, action) => {
      state.checkoutProducts = action.payload;
    },
    SetstripPI_ID: (state, action) => {
      state.stripPI_ID = action.payload;
    },
    totalAmount: (state, action) => {
      state.totalAmount = action.payload;
      // localStorage.setItem("FP", action.payload);
    },
    PaypalPaymentStatusCleanUp: (state, action) => {
      state.PaypalPaymentStatus = "";
    },
  },
  extraReducers: (builder) => {
    //sending orders
    builder
      .addCase(sendOrder.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendOrder.fulfilled, (state, action) => {
        state.orderID = action.payload;
        state.loading = false;
      })
      .addCase(sendOrder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to send order to API";
        toast.error("Some thing went wrong");
      })
      .addCase(paypalStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(paypalStatus.fulfilled, (state, action) => {
        state.PaypalPaymentStatus = action.payload;
        state.loading = false;
      })
      .addCase(paypalStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to send order to API";
        toast.error("Some thing went wrong");
      });
  },
});
export const {
  checkOutProductData,
  SetstripPI_ID,
  totalAmount,
  PaypalPaymentStatusCleanUp,
} = OrderSlice.actions;
export default OrderSlice.reducer;
