import React, { useEffect, useState } from "react";
// MUI
import { Box, useMediaQuery } from "@mui/material";
import { Grid, Stack, useTheme, Divider } from "@mui/material";
//css
import "./HomePageContentDesktop.css";
import SliderCarousel from "../Slider/SliderCarousel";
import freeShipping from "../../../svg/fi-free-delivery-48.svg";
import hour24 from "../../../svg/fi-24-hours-48.svg";
import paymentSecurity from "../../../svg/fi-payment-security-48.svg";
import hotOffer from "../../../svg/fi-tag-48.svg";
import brand from "../../../svg/brand-2.png";
import { useDispatch, useSelector } from "react-redux";
import {
  GetManufacturerList,
  GetProductDetails,
  getFilterDataX,
  getProductsCount,
  getVehicleId,
  setdataCleanUp,
  setselectedMfg,
  setvehicleFinderObj,
} from "../../../Redux/Reducers/reducerSlices/ProductsSlices/ProductDetailsSlice";
import { CenteredGrid, ColorButtonYellow } from "../../../Themes/Theme";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../Shared/Breadcrumb";
import WaveHeader from "../../Shared/WaveHeader/WaveHeader";
export default function HomePageContentDesktop() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const direction = isMediumScreen ? "column" : "row";
  const items = Array.from({ length: 16 });
  const dispatch = useDispatch();
  const {
    Mdata,
    countObj,
    model,
    year,
    series,
    cc,
    fuelType,
    bodyType,
    manufacturer,
    yearFrom,
    vehicleIDs,
    isLoading,
  } = useSelector((state) => state.ProductDetails);
  const state = useSelector((state) => state.ProductDetails);
  const userData = useSelector((state) => state.login.data[0]);
  // console.log(vehicleIDs, "vehicle IDs all");
  // console.log(state, model, manufacturer, year, series, cc, fuelType, bodyType);
  const [selectOptions, setSelectOptions] = useState({
    manufacturer: null,
    model: null,
    year: null,
    series: null,
    cc: null,
    fuelType: null,
    bodyType: null,
  });

  useEffect(() => {
    dispatch(GetManufacturerList());
  }, []);
  const handleSection = (value, type, id = 1) => {
    // console.log(value, type);
    const obj = selectOptions;
    obj[type] = value;
    setSelectOptions((prevstate) => ({ ...prevstate, ...obj }));
    if (value) {
      // console.log("yyyyyyyyyyyyyyyyy");
      if (selectOptions.manufacturer)
        dispatch(setselectedMfg(selectOptions.manufacturer));
      dispatch(
        getProductsCount({
          manufacturer: null,
          model: null,
          year: null,
          series: null,
          cc: null,
          fuelType: null,
          bodyType: null,
          ...obj,
        })
      );
      dispatch(
        getVehicleId({
          manufacturer: null,
          model: null,
          year: null,
          series: null,
          cc: null,
          fuelType: null,
          bodyType: null,
          ...obj,
        })
      );
      // console.log("iiiiiiiiiiiiiiiiiiiiiiiiiii", obj);
      id &&
        dispatch(
          getFilterDataX({
            action: {
              manufacturer: null,
              model: null,
              series: null,
              cc: null,
              fuelType: null,
              type: id,
              ...obj,
              yearFrom: type === "year" ? value : selectOptions.year || null,
            },
            type: type == "year" ? "yearFrom" : type,
          })
        );

      dispatch(setvehicleFinderObj(obj));
    }
  };

  const globalSearch = () => {
    dispatch(setdataCleanUp());
    dispatch(
      GetProductDetails({
        SearchInput: vehicleIDs?.vehicleID,
        type: 1,
        userID: userData?.id || null,
      })
    );
    if (!isLoading) {
      navigate("/ProductView");
    }
  };
  return (
    <Box
      sx={{
        background: "#fafafa",
        padding: {
          xl: "12px 5em ",
          lg: "12px 5em ",
          sm: "12px 3em ",
          xs: "12px 1em ",
        },
        zIndex: "-1",
      }}
    >
      {/* <SliderCarousel /> */}
      <Stack direction={"row"} mb={1.1}>
        <Breadcrumb text={"Home"} />
      </Stack>
      <div className="wave-and-finder-wrapper">
        <WaveHeader title={"Vehicle Finder"} type={"vehicleFinder"} />
        <div id="vehicle-finder" className="section-container">
          <div className="dd-containser">
            <Grid container spacing={2} display={"flex"}>
              <CenteredGrid
                item
                xs={3}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <select
                  className="select-sectio"
                  onChange={(e) =>
                    handleSection(e.target.value, "manufacturer", 1)
                  }
                >
                  <option value={null} title="">
                    Manufacturer
                  </option>
                  {Mdata && Mdata?.length ? (
                    <>
                      {Mdata.map((e, i) => (
                        <option
                          key={i}
                          title={e?.manufacturer}
                          value={e?.manufacturer}
                        >
                          {e.manufacturer}
                        </option>
                      ))}
                    </>
                  ) : (
                    ""
                  )}
                </select>
              </CenteredGrid>
              {selectOptions.manufacturer ? (
                <CenteredGrid item xs={3}>
                  <select
                    className="select-sectio"
                    onChange={(e) => handleSection(e.target.value, "model", 2)}
                  >
                    <option value={null}>Model</option>
                    {manufacturer && manufacturer?.length ? (
                      <>
                        {manufacturer.map((e, i) => (
                          <option key={i} title={e?.value} value={e?.value}>
                            {e.value}
                          </option>
                        ))}
                      </>
                    ) : (
                      ""
                    )}{" "}
                  </select>
                </CenteredGrid>
              ) : (
                ""
              )}
              {selectOptions.manufacturer && selectOptions.model ? (
                <CenteredGrid item xs={3}>
                  <select
                    className="select-sectio"
                    onChange={(e) => handleSection(e.target.value, "year", 3)}
                  >
                    <option value={null}>Year</option>
                    {model && model?.length ? (
                      <>
                        {model.map((e, i) => (
                          <option key={i} title={e?.value} value={e?.value}>
                            {e.value}
                          </option>
                        ))}
                      </>
                    ) : (
                      ""
                    )}
                  </select>
                </CenteredGrid>
              ) : (
                ""
              )}

              {selectOptions.manufacturer &&
              selectOptions.model &&
              selectOptions.year ? (
                <CenteredGrid item xs={3}>
                  <select
                    className="select-sectio"
                    onChange={(e) => handleSection(e.target.value, "series", 4)}
                  >
                    <option value={null}>series</option>
                    {yearFrom && yearFrom?.length ? (
                      <>
                        {yearFrom.map((e, i) => (
                          <option key={i} title={e?.value} value={e?.value}>
                            {e.value}
                          </option>
                        ))}
                      </>
                    ) : (
                      ""
                    )}
                  </select>
                </CenteredGrid>
              ) : (
                ""
              )}
              {selectOptions.manufacturer &&
              selectOptions.model &&
              selectOptions.year &&
              selectOptions.series ? (
                <CenteredGrid item xs={3}>
                  <select
                    className="select-sectio"
                    onChange={(e) => handleSection(e.target.value, "cc", 5)}
                  >
                    <option value={null}>Capacity</option>
                    {series && series?.length ? (
                      <>
                        {series.map((e, i) => (
                          <option key={i} title={e?.value} value={e?.value}>
                            {e.value}
                          </option>
                        ))}
                      </>
                    ) : (
                      ""
                    )}{" "}
                  </select>
                </CenteredGrid>
              ) : (
                ""
              )}
              {selectOptions.manufacturer &&
              selectOptions.model &&
              selectOptions.year &&
              selectOptions.series &&
              selectOptions.cc ? (
                <CenteredGrid item xs={3}>
                  <select
                    className="select-sectio"
                    onChange={(e) =>
                      handleSection(e.target.value, "fuelType", 6)
                    }
                  >
                    <option value={null}>Fuel Type</option>
                    {cc && cc?.length ? (
                      <>
                        {cc.map((e, i) => (
                          <option key={i} title={e?.value} value={e?.value}>
                            {e.value}
                          </option>
                        ))}
                      </>
                    ) : (
                      ""
                    )}{" "}
                  </select>
                </CenteredGrid>
              ) : (
                ""
              )}

              {selectOptions.manufacturer &&
              selectOptions.model &&
              selectOptions.year &&
              selectOptions.series &&
              selectOptions.cc &&
              selectOptions.fuelType ? (
                <CenteredGrid item xs={3}>
                  <select
                    className="select-sectio"
                    onChange={(e) =>
                      handleSection(e.target.value, "bodyType", 0)
                    }
                  >
                    <option value={null}>Body Type</option>
                    {fuelType && fuelType?.length ? (
                      <>
                        {fuelType.map((e, i) => (
                          <option key={i} title={e?.value} value={e?.value}>
                            {e.value}
                          </option>
                        ))}
                      </>
                    ) : (
                      ""
                    )}{" "}
                  </select>
                </CenteredGrid>
              ) : (
                ""
              )}
            </Grid>
            <div className="result-button">
              <ColorButtonYellow
                onClick={globalSearch}
                className="NBM-ColorButtonText"
                startIcon={<DirectionsCarIcon />}
                disabled={isLoading}
              >
                {isLoading ? "Loading...." : "Search"}
              </ColorButtonYellow>
              <div className="header-section">
                <h5>{`Vehicles Found : ${countObj.vehicleCount}`}</h5>
              </div>
              <div className="header-section">
                <h5>{`Parts Found : ${countObj.partCount}`}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="HPC-D-Text-Main">
        {/* <Grid
          justifyContent={"center"}
          container
          mb={5}
          sx={{
            boxShadow:
              "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,rgba(228, 228, 15, 0.15) 0px 0px 0px 1px",
            padding: "3px",
            background: "#fff",
            borderRadius: "3px",
          }}
        >
          {items.map((_, index) => (
            <Grid
              key={index}
              item
              sx={{
                // margin: "0.5px",
                display: "flex",
                flexDirection: "column",
                padding: "8px",
                color: "#737373",
                border: "1px solid #fafafa",
                background: "#fff",
              }}
              alignItems={"center"}
              xs={3}
              sm={2}
              lg={1.5}
            >
              <img
                src={brand}
                alt={"img"}
                style={{
                  height: "80px",
                  width: "80px",
                }}
              />
              <div>sometext</div>
            </Grid>
          ))}
        </Grid> */}
        <h1
          className={
            isMediumScreen
              ? "HomePage-Content-Desktop-Title-Text"
              : "HomePage-Content-mobile-Title-Text"
          }
        >
          International Deliveries Via DHL
        </h1>
        <Divider orientation="horizontal" />
        <Grid
          justifyContent={"center"}
          container
          spacing={2}
          columns={{ xs: 2, sm: 8, md: 16 }}
          mt={5}
          mb={5}
        >
          <Grid
            item
            xs={2}
            sm={4}
            md={4}
            display={"flex"}
            justifyContent={"center"}
          >
            <Stack direction={direction} spacing={2}>
              <div>
                <img src={freeShipping} style={{ objectFit: "scale-down" }} />
              </div>
              <Stack direction={"column"}>
                <div>Free Shipping</div>
                <div>For orders from $50</div>
              </Stack>
            </Stack>
          </Grid>
          <Grid
            item
            xs={2}
            sm={4}
            md={4}
            display={"flex"}
            justifyContent={"center"}
          >
            <Stack direction={direction} spacing={2}>
              <div>
                <img src={hour24} style={{ objectFit: "scale-down" }} />
              </div>
              <Stack direction={"column"}>
                <div>Support 24/7</div>
                <div>Call us anytime</div>
              </Stack>
            </Stack>
          </Grid>
          <Grid
            item
            xs={2}
            sm={4}
            md={4}
            display={"flex"}
            justifyContent={"center"}
          >
            <Stack direction={direction} spacing={2}>
              <div>
                <img
                  src={paymentSecurity}
                  style={{ objectFit: "scale-down" }}
                />
              </div>
              <Stack direction={"column"}>
                <div>100% Safety</div>
                <div>Only secure payments</div>
              </Stack>
            </Stack>
          </Grid>
          <Grid
            item
            xs={2}
            sm={4}
            md={4}
            display={"flex"}
            justifyContent={"center"}
          >
            <Stack direction={direction} spacing={2}>
              <div>
                <img src={hotOffer} style={{ objectFit: "scale-down" }} />
              </div>
              <Stack direction={"column"}>
                <div>Hot Offers</div>
                <div>Discounts up to 90%</div>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
}
