import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../../Constants/Constants";
import { toast } from "react-toastify";
const initialState = {
  loadingCountryDetails: false,
  loadingShippingDetail: false,
  error: null,
  countryNames: [],
  shippingDetails: [],
};

export const getCountryDetails = createAsyncThunk(
  "country/getCountryDetails",
  async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}GetCountryDetails?Type=${1}`
      );
      // console.log(mappedProducts);
      return response.data.value;
    } catch (error) {
      throw Error(error.response?.data?.message || "Something went wrong..");
    }
  }
);
export const getShippingDetail = createAsyncThunk(
  "country/getShippingDetail",
  async ({ country, weight }) => {
    // console.log(country, weight);
    try {
      const response = await axios.get(
        `${BASE_URL}GetShippingDetails?Country=${country}&Weight=${weight}`
      );
      // console.log(mappedProducts);
      return response.data.value;
    } catch (error) {
      throw Error(error.response?.data?.message || "Something went wrong..");
    }
  }
);

const CountrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {
    cleanUpshippingDetails: (state) => {
      state.shippingDetails = [];
    },
  },
  extraReducers: (builder) => {
    //getting country details
    builder
      .addCase(getCountryDetails.pending, (state) => {
        state.loadingCountryDetails = true;
        state.error = null;
      })
      .addCase(getCountryDetails.fulfilled, (state, action) => {
        state.countryNames = action.payload;
        state.loadingCountryDetails = false;
      })
      .addCase(getCountryDetails.rejected, (state, action) => {
        state.loadingCountryDetails = false;
        state.error = action.error.message || "Failed to send order to API";
        toast.error("Some thing went wrong");
      })
      // getting shipping details
      .addCase(getShippingDetail.pending, (state) => {
        state.loadingShippingDetail = true;
        state.error = null;
      })
      .addCase(getShippingDetail.fulfilled, (state, action) => {
        state.shippingDetails = action.payload;
        state.loadingShippingDetail = false;
      })
      .addCase(getShippingDetail.rejected, (state, action) => {
        state.loadingShippingDetail = false;
        state.error = action.error.message || "Failed to send order to API";
        toast.error("Some thing went wrong");
      });
  },
});
export const { cleanUpshippingDetails } = CountrySlice.actions;
export default CountrySlice.reducer;
