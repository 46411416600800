import React, { useEffect, useState } from "react";
import "./WaveHeader.css";
export default function WaveHeader({ title, type }) {
  const [fs, setFs] = useState("1rem");
  useEffect(() => {
    if (type === "Login") setFs("1.2rem");
    else if (type === "Cart") setFs("2.25rem");
    else if (type === "Register") setFs("2.25rem");
    else setFs("2.25rem");
  }, [type]);
  return (
    <div className="add-details-header-wrapper">
      <div
        style={{
          fontSize: fs,
          paddingTop: "1em",
        }}
        className="Product-view-title-sx"
      >
        {title}
      </div>
    </div>
  );
}
