import { configureStore } from "@reduxjs/toolkit";
import UserLoginSlice from "../Reducers/reducerSlices/LoginSlices/LoginSlice";

import ProductDetailsSlice from "../Reducers/reducerSlices/ProductsSlices/ProductDetailsSlice";
import ComponentActionsSlice from "../Reducers/reducerSlices/componentActionsSlices/ComponentActionsSlice";
import AddtoCartSlice, {
  initializeCartFromLocalStorage,
} from "../Reducers/reducerSlices/AddToCartSlice/AddtoCartSlice";
import OrderSlice from "../Reducers/reducerSlices/ChechOutSlice/OrderSlice";
import MyOrderSlice from "../Reducers/reducerSlices/MyOrderSlice/MyOrderSlice";
import CountrySlice from "../Reducers/reducerSlices/CountrySlice/CountrySlice";
import AdminSlice from "../Reducers/reducerSlices/AdminSlice/AdminSlice";
export const store = configureStore({
  reducer: {
    login: UserLoginSlice,
    ProductDetails: ProductDetailsSlice,
    ComponentAction: ComponentActionsSlice,
    addToCart: AddtoCartSlice,
    order: OrderSlice,
    MyOrders: MyOrderSlice,
    Country: CountrySlice,
    AdminDetails: AdminSlice,
  },
});
store.dispatch(initializeCartFromLocalStorage());
