import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { removeProduct } from "../../../Redux/Reducers/reducerSlices/AddToCartSlice/AddtoCartSlice";
import {
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  IconButton,
  Box,
  CardMedia,
  Stack,
  Grid,
  useMediaQuery,
  MenuItem,
  Select,
  Checkbox,
  FormControlLabel,
  Typography,
  CircularProgress,
} from "@mui/material";
import PropTypes from "prop-types";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  BASE_URL,
  IMAGE_BASE_URL,
  PAYPAL_URL,
} from "../../../Constants/Constants";
import "./CartDisplay.css";
import Breadcrumb from "../../Shared/Breadcrumb";
import ProductCard from "../ProductCard";
import QuantityByn from "../../Shared/QuantityByn";
import {
  checkOutProductData,
  sendOrder,
  totalAmount,
} from "../../../Redux/Reducers/reducerSlices/ChechOutSlice/OrderSlice";
import errorImg from "../../../svg/brand-2.png";
import productNotFound from "../../../svg/undraw_No_data_re_kwbl.png";
import { useNavigate } from "react-router-dom";
import Loader from "../../Shared/Loader";
import { BootstrapInput } from "../../../Themes/Theme";
import axios from "axios";
import WaveHeader from "../../Shared/WaveHeader/WaveHeader";
import {
  cleanUpshippingDetails,
  getCountryDetails,
  getShippingDetail,
} from "../../../Redux/Reducers/reducerSlices/CountrySlice/CountrySlice";
import { toast } from "react-toastify";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CartTable = () => {
  const isSmallScreen = useMediaQuery("(min-width:1025px)");
  const userData = useSelector((state) => state.login.data[0]);
  // console.log(userData, "from redux");
  const cartProducts = useSelector((state) => state.addToCart.cartProducts);
  const { loading } = useSelector((state) => state.order);
  const {
    countryNames,
    shippingDetails,
    loadingCountryDetails,
    loadingShippingDetail,
  } = useSelector((state) => state.Country);
  // console.log(shippingDetails);
  const [shippingTaxCharge, setShippingTaxCharge] = useState(0);
  const [shippingService, setShippingService] = useState({
    price: "0.00",
    service: "FREE - Collection from depot",
    shippingVAT: "0.00",
  });
  // console.log(shippingService);
  const [open, setOpen] = React.useState(false);
  const [Udata, setUdata] = useState();
  // console.log(Udata);
  const [imgError, setImgError] = useState(false);
  const [addressErrors, setAddressErrors] = useState(null);
  const [checked, setChecked] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [next, setNext] = useState(false);
  const [PaypalLoading, setPaypalLoading] = useState(0);
  const [isValid, setIsValid] = useState(false);
  const [fieldError, setFieldError] = useState({
    delivery_Name: false,
    ccAdd1: false,
    county: false,
    country: false,
    postcode: false,
    billing_Name: false,
    billing_ccAdd1: false,
    billing_ccAdd2: false,
    billing_ccTown: false,
    billing_county: false,
    billing_country: false,
    billing_postcode: false,
  });
  // const [shippingState, setShippingState] = useState("");
  const [vat, setVat] = useState(0);
  const [vatShipping, setVatShipping] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let total = 0; // used dont remove

  let totalweight = 0;
  let totalNetWeight = 0;
  const prevWeight = cartProducts.forEach(
    (p) => (totalNetWeight += p.weight * p.quantity)
  );
  // console.log(totalNetWeight);

  useEffect(() => {
    if (userData) {
      setUdata({
        ...userData,
        delivery_Name: `${userData.firstName} ${userData.lastName}`,
        billing_Name: "",
        billing_ccAdd1: "",
        billing_ccAdd2: "",
        billing_ccTown: "",
        billing_country: "",
        billing_county: "",
        billing_postcode: "",
        ccAdd1: userData.add1,
        ccAdd2: userData.add2,
        ccTown: userData.town,
        ccCountry: userData.country,
      });
    }
  }, [userData, tabValue]);
  useEffect(() => {
    dispatch(getCountryDetails());
  }, []);

  useEffect(() => {
    userData
      ? dispatch(
          getShippingDetail({
            country: userData?.country,
            weight: totalNetWeight,
          })
        )
      : dispatch(cleanUpshippingDetails());
  }, [userData]);
  useEffect(() => {
    if (userData && countryNames) {
      const cn = countryNames.filter((n) => n.country === userData.country);
      // console.log(cn[0]);
      handleVatChange(cn[0]);
    }
  }, [userData, countryNames, cartProducts]);
  // console.log(Udata);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleRemoveProduct = (productId) => {
    dispatch(removeProduct(productId));
  };
  const handleChange = (event) => {
    // console.log(event.target.checked);
    setChecked(event.target.checked);
    if (checked) {
      setTabValue(0);
    }
  };
  const calculateTotalPrice = (price, quantity) => {
    const priceString = price.replace(/[^\d.]/g, ""); // Remove non-numeric characters
    const priceValue = parseFloat(priceString) * quantity;
    total = priceValue;
    return priceValue;
  };

  const calculateTotalWeight = (weight, quantity) => {
    // console.log(weight, quantity);
    totalweight = weight * quantity;
    return weight * quantity;
  };

  const totalPrice = cartProducts.reduce(
    (s, i) => s + calculateTotalPrice(i?.retailPrice, i?.quantity),
    0
  );

  // const vatCharge = (totalPrice * parseInt(vat)) / 100 || 0;
  // console.log(vatCharge);
  const IDGenerator = `IC${(new Date().getDate() + "").padStart(2, "0")}${(
    new Date().getMonth() +
    1 +
    ""
  ).padStart(2, "0")}${new Date().getFullYear()}${(
    new Date().getHours() + ""
  ).padStart(2, "0")}${(new Date().getMinutes() + "").padStart(2, "0")}${(
    new Date().getSeconds() + ""
  ).padStart(2, "0")}${(new Date().getMilliseconds() + "").padStart(3, "0")}`;

  const handleShippingTax = (e, d, t = "") => {
    if (t === "d") {
      setShippingTaxCharge(parseFloat("0.00"));
      setVatShipping(parseFloat("0.00"));
      setShippingService((prev) => ({
        ...prev,
        service: "FREE - Collection from depot",
        price: "0.00",
        shippingVAT: "0.00",
      }));
    } else {
      setShippingTaxCharge(parseFloat(e.target.value));
      setShippingService(d);
      const sv = parseFloat(d.shippingVAT);
      setVatShipping(sv);
    }
  };

  const finalPrice = (
    totalPrice +
    vat +
    shippingTaxCharge +
    vatShipping
  ).toFixed(2);
  const handleAddressDetails = (e) => {
    const { name, value } = e.target;
    // console.log(value);
    setFieldError({ ...fieldError, [name]: false, emailValid: false });
    if (name === "country") {
      // console.log(value);
      setShippingTaxCharge(parseFloat("0.00"));
      setVatShipping(parseFloat("0.00"));
      setShippingService((prev) => ({
        ...prev,
        service: "FREE - Collection from depot",
        price: "0.00",
        shippingVAT: "0.00",
      }));
      dispatch(getShippingDetail({ country: value, weight: totalNetWeight }));
    }
    setUdata({ ...Udata, [name]: value });
  };

  const errorHandler = (t) => {
    const current = t === "billing" ? 1 : 0;

    setAddressErrors(`All ${t} fields are mandatory...`);
  };
  const PayPalKeyGen = async () => {
    setPaypalLoading(true);
    try {
      const res = await axios.post(
        BASE_URL + `PaypalOrder?amount=${finalPrice}`
      );
      // console.log(res.data);
      window.open(`${PAYPAL_URL}${res?.data}`, "_blank");
      navigate(`/PayPalConfirmation/${res?.data}`);
      setPaypalLoading(false);
      return res;
    } catch (e) {
      // console.log("error paypal", e.message);
      setPaypalLoading(false);
      return;
    }
  };

  const handleCheckOut = (user, type) => {
    // console.log(type);
    let flag = true;

    if (userData?.length === 0 || userData === undefined) {
      flag = false;
      toast.warning("Please login or signup to continue shopping");
    }
    if (
      Udata?.delivery_Name === undefined ||
      Udata?.delivery_Name.length === 0 ||
      Udata?.delivery_Name === ""
    ) {
      flag = false;
      setFieldError((prevFieldError) => ({
        ...prevFieldError,
        delivery_Name: true,
      }));

      errorHandler("delivery");
    }

    if (
      Udata?.ccAdd1 === undefined ||
      Udata?.ccAdd1.length === 0 ||
      Udata?.ccAdd1 === ""
    ) {
      flag = false;
      setFieldError((prevFieldError) => ({
        ...prevFieldError,
        ccAdd1: true,
      }));
      errorHandler("delivery");
    }

    if (
      Udata?.ccTown === undefined ||
      Udata?.ccTown.length === 0 ||
      Udata?.ccTown === ""
    ) {
      flag = false;
      setFieldError((prevFieldError) => ({
        ...prevFieldError,
        ccTown: true,
      }));
      errorHandler("delivery");
    }
    if (
      Udata?.county === undefined ||
      Udata?.county.length === 0 ||
      Udata?.county === ""
    ) {
      flag = false;
      setFieldError((prevFieldError) => ({
        ...prevFieldError,
        county: true,
      }));
      errorHandler("delivery");
    }
    if (
      Udata?.country === undefined ||
      Udata?.country.length === 0 ||
      Udata?.country === ""
    ) {
      flag = false;
      setFieldError((prevFieldError) => ({
        ...prevFieldError,
        country: true,
      }));
      errorHandler("delivery");
    }
    if (
      Udata?.postcode === undefined ||
      Udata?.postcode.length === 0 ||
      Udata?.postcode === ""
    ) {
      flag = false;
      setFieldError((prevFieldError) => ({
        ...prevFieldError,
        postcode: true,
      }));
      errorHandler("delivery");
    }
    if (
      checked === false &&
      (Udata?.billing_Name === undefined ||
        Udata?.billing_Name.length === 0 ||
        Udata?.billing_Name === "")
    ) {
      flag = false;
      setFieldError((prevFieldError) => ({
        ...prevFieldError,
        billing_Name: true,
      }));
      errorHandler("billing");
    }
    if (
      checked === false &&
      (Udata?.billing_ccAdd1 === undefined ||
        Udata?.billing_ccAdd1.length === 0 ||
        Udata?.billing_ccAdd1 === "")
    ) {
      flag = false;
      setFieldError((prevFieldError) => ({
        ...prevFieldError,
        billing_ccAdd1: true,
      }));
      errorHandler("billing");
    }
    if (
      checked === false &&
      (Udata?.billing_ccAdd2 === undefined ||
        Udata?.billing_ccAdd2.length === 0 ||
        Udata?.billing_ccAdd2 === "")
    ) {
      flag = false;
      setFieldError((prevFieldError) => ({
        ...prevFieldError,
        billing_ccAdd2: true,
      }));
      errorHandler("billing");
    }
    if (
      checked === false &&
      (Udata?.billing_ccTown === undefined ||
        Udata?.billing_ccTown.length === 0 ||
        Udata?.billing_ccTown === "")
    ) {
      flag = false;
      setFieldError((prevFieldError) => ({
        ...prevFieldError,
        billing_ccTown: true,
      }));
      errorHandler("billing");
    }
    if (
      checked === false &&
      (Udata?.billing_county === undefined ||
        Udata?.billing_county.length === 0 ||
        Udata?.billing_county === "")
    ) {
      flag = false;
      setFieldError((prevFieldError) => ({
        ...prevFieldError,
        billing_county: true,
      }));
      errorHandler("billing");
    }
    if (
      checked === false &&
      (Udata?.billing_country === undefined ||
        Udata?.billing_country.length === 0 ||
        Udata?.billing_country === "")
    ) {
      flag = false;
      setFieldError((prevFieldError) => ({
        ...prevFieldError,
        billing_country: true,
      }));
      errorHandler("billing");
    }
    if (
      checked === false &&
      (Udata?.billing_postcode === undefined ||
        Udata?.billing_postcode.length === 0 ||
        Udata?.billing_postcode === "")
    ) {
      flag = false;
      setFieldError((prevFieldError) => ({
        ...prevFieldError,
        billing_postcode: true,
      }));
      errorHandler("billing");
    }
    // console.log(flag);
    return flag;
    // console.log(cartProducts);
  };
  const handleSubmit = (user, type) => {
    errorHandler(null);
    if (handleCheckOut(user, type)) {
      const mappedProducts = cartProducts.map((product) => ({
        executionType: 1,
        orderNo: IDGenerator,
        productCode: product.productcode,
        quantity: product.quantity,
        customerID: user.id || 0,
        delivery_Name:
          user.firstName && user.lastName
            ? `${user.firstName + " "}${user.lastName}`
            : `${user.delivery_Name}`,
        delivery_Add1: user.ccAdd1,
        delivery_Add2: user.ccAdd2,
        delivery_Town: user.ccTown,
        delivery_County: user.county,
        delivery_Postcode: user.postcode,
        delivery_Country: user.country,
        cC_Name: checked ? user.delivery_Name : Udata.billing_Name, // Assuming you have a field to capture the delivery name or similar
        cC_Add1: checked ? user.ccAdd1 : Udata.billing_ccAdd1,
        cC_Add2: checked ? user.ccAdd2 : Udata.billing_ccAdd2,
        cC_Town: checked ? user.ccTown : Udata.billing_ccTown,
        cC_County: checked ? user.county : Udata.billing_county,
        cC_Postcode: checked ? user.postcode : Udata.billing_postcode,
        cC_Country: checked ? user.country : Udata.billing_country,
        perUnitPrice: parseFloat(
          product.retailPrice.replace(/[^\d.]/g, "")
        ).toFixed(2),
        // perUnitVAT: (
        //   (parseFloat(product.retailPrice.replace(/[^\d.]/g, "")) * 20) /
        //   100
        // ).toFixed(2),
        perUnitVAT: vat.toFixed(2),
        shippingBand: "",
        shippingName: shippingService?.service,
        shippingNett: shippingTaxCharge.toFixed(2),
        shippingVAT: vatShipping.toFixed(2),
        status: "Shopping Cart",
        date: new Date().toISOString(),
        supplierCode: null,
        paymentMethod: null,
        note: null,
        customerOrderNo: null,
        issueNumber: 0,
        headOffice: 0,
        companyName: null,
        paidDate: new Date().toISOString(),
        ePDQ_LastTransactionType: "none",
        ePDQ_AuthCode: "none",
        orderTime: new Date().toISOString(),
        orderedBy: "",
        surcharge: 0.0,
        handlingCharge: 0.0,
        numberSold: 0,
        trackingNo: null,
        courierName: shippingService?.service,
        courierCollectionDate: new Date().toISOString(),
        courierCollectionDeliveryDate: new Date().toISOString(),
        courierCollectionReference: "",
        orderType: "order",
        options: "",
        vehicleID: 0,
      }));
      // console.log(mappedProducts);
      dispatch(checkOutProductData(mappedProducts));
      dispatch(totalAmount(finalPrice));
      dispatch(sendOrder(mappedProducts))
        .then(() => {
          if (type === "strip") {
            navigate("/StripGateWay");
            type = "";
          }
          if (type === "paypal") {
            // navigate("/PayPalPaymentGateway");
            PayPalKeyGen();
          }
        })
        .catch((e) => {});
      setOpen(false);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };
  const errorDiv = (field) => {
    if (field === "unmatch")
      return <p className="error-div">Password doesn't match</p>;
    if (field === "emailnotvalid")
      return <p className="error-div">Please enter valid email</p>;
    return <p className="error-div">{field} is Mandatory</p>;
  };
  const errorImgHandler = () => [setImgError(true)];
  const handleVatChange = (data) => {
    // console.log(data);
    // console.log(totalPrice);
    const v = (totalPrice * parseInt(data?.vatRate)) / 100 || 0;
    setVat(v);
  };

  // console.log(typeof vatShipping);
  return (
    <div className="product-view-body">
      <Container maxWidth="xl">
        {loading ? (
          <Loader />
        ) : (
          <>
            <Stack direction={"row"} mb={1}>
              <Breadcrumb text={"Home"} />
              <Breadcrumb text={"Shopping Cart"} />
            </Stack>
            {/* cart table section */}
            <div className="card-display-wrapper">
              <div style={{ background: "#fff" }}>
                <WaveHeader title={"Shopping Cart"} type={"Cart"} />
                {isSmallScreen ? (
                  <div
                    style={{
                      width: "100%",
                      marginRight: "50px",
                      display: "flex",
                    }}
                  >
                    {cartProducts.length ? (
                      <TableContainer component={Paper}>
                        <Table aria-label="cart-table">
                          <TableHead>
                            <TableRow>
                              {/* <TableCell>Sr No.</TableCell> */}
                              <TableCell align="center">Image</TableCell>
                              <TableCell>Product</TableCell>
                              <TableCell>Price</TableCell>
                              <TableCell align="center">Quantity</TableCell>
                              <TableCell align="center">Weight</TableCell>
                              <TableCell align="center">Total</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {cartProducts.map((product, i) => (
                              <TableRow key={product?.productcode}>
                                {/* <TableCell>{i+1}</TableCell> */}
                                <TableCell>
                                  <CardMedia
                                    sx={{
                                      objectFit: "scale-down",
                                      paddingRight: "5px",
                                      paddingLeft: "25px",
                                      maxWidth: "250px",
                                    }}
                                    component="img"
                                    height="100px"
                                    width="100px"
                                    image={
                                      imgError
                                        ? errorImg
                                        : IMAGE_BASE_URL + product?.pictureName
                                    }
                                    alt={product.name}
                                    onError={errorImgHandler}
                                  />
                                </TableCell>
                                <TableCell>
                                  <div>
                                    <p>{product?.productcode}</p>
                                    <p>{product?.manufacturer || "-"}</p>
                                  </div>
                                </TableCell>
                                <TableCell>{product?.retailPrice}</TableCell>
                                <TableCell>
                                  <Box
                                    display={"flex"}
                                    justifyContent={"space-evenly"}
                                    alignItems={"center"}
                                  >
                                    <QuantityByn
                                      qty={product?.quantity}
                                      product={product}
                                      disabled={next}
                                    />
                                  </Box>
                                </TableCell>
                                <TableCell align="center">
                                  {calculateTotalWeight(
                                    product?.weight,
                                    product?.quantity
                                  )}
                                  gm
                                </TableCell>
                                <TableCell align="center">
                                  <Box>
                                    £
                                    {calculateTotalPrice(
                                      product?.retailPrice,
                                      product?.quantity
                                    ).toFixed(2)}
                                    {!next && (
                                      <IconButton
                                        onClick={() =>
                                          handleRemoveProduct(
                                            product.productcode
                                          )
                                        }
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    )}
                                  </Box>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                        {!next && (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              paddingRight: "5%",
                              margin: "10px 20px",
                            }}
                          >
                            <button
                              disabled={cartProducts.length ? false : true}
                              onClick={() => setNext(true)}
                              className={"cart-checkout-btn"}
                            >
                              Proceed
                            </button>
                          </Box>
                        )}
                      </TableContainer>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <img
                          src={productNotFound}
                          style={{
                            height: "200px",
                            width: "200px",
                            objectFit: "contain",
                          }}
                        />
                        <div className="Product-view-title">
                          Please add product to Cart
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  // responsive cart section with cards
                  <Box
                    mt={2}
                    sx={{
                      height: "auto",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Grid container spacing={3}>
                      {cartProducts.length ? (
                        cartProducts.map((product, i) => (
                          <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
                            <ProductCard key={i} product={product} />
                            <div className="cart-view-bottomtocard-wrapper">
                              <Stack
                                padding={"5px 15px"}
                                direction={"row"}
                                justifyContent={"space-between"}
                              >
                                <div className="cart-view-bottomtocard-details">
                                  Price:
                                </div>
                                <div className="cart-view-bottomtocard-details">
                                  £
                                  {calculateTotalPrice(
                                    product?.retailPrice,
                                    product?.quantity
                                  ).toFixed(2)}
                                </div>
                              </Stack>
                            </div>
                            <div className="cart-view-bottomtocard-wrapper">
                              <Stack
                                padding={"5px 15px"}
                                direction={"row"}
                                justifyContent={"space-between"}
                              >
                                <div className="cart-view-bottomtocard-details">
                                  Quantity:
                                </div>
                                <div className="cart-view-bottomtocard-details">
                                  <QuantityByn
                                    qty={product?.quantity}
                                    product={product}
                                  />
                                </div>
                              </Stack>
                            </div>
                            <div className="cart-view-bottomtocard-wrapper">
                              <Stack
                                padding={"5px 15px"}
                                direction={"row"}
                                justifyContent={"space-between"}
                              >
                                <div className="cart-view-bottomtocard-details">
                                  Weight:
                                </div>
                                <div className="cart-view-bottomtocard-details">
                                  {product?.weight} gm
                                </div>
                              </Stack>
                            </div>
                            <div className="cart-view-bottomtocard-wrapper">
                              <Stack
                                padding={"5px 15px"}
                                direction={"row"}
                                justifyContent={"space-between"}
                              >
                                <div className="cart-view-bottomtocard-details">
                                  Total:
                                </div>
                                <div className="cart-view-bottomtocard-details">
                                  $550.20
                                </div>
                              </Stack>
                            </div>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                paddingRight: "10%",
                              }}
                            >
                              <button
                                disabled={cartProducts.length ? false : true}
                                onClick={() => setNext(true)}
                                className={"cart-checkout-btn"}
                              >
                                Next
                              </button>
                            </Box>
                          </Grid>
                        ))
                      ) : (
                        //product not found
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <img
                            src={productNotFound}
                            style={{
                              height: "200px",
                              width: "200px",
                              objectFit: "contain",
                            }}
                          />
                          <div className="Product-view-title">
                            Please add product to Cart
                          </div>
                        </div>
                      )}
                    </Grid>
                  </Box>
                )}
              </div>
              {next && (
                <>
                  <div className="add-details-hero">
                    <div style={{ width: "100%" }}></div>
                    <Box sx={{ width: "100%" }}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                          value={tabValue}
                          onChange={handleTabChange}
                          aria-label="basic tabs example"
                        >
                          <Tab label="Delivery Address" {...a11yProps(0)} />
                          {!checked && (
                            <Tab label="Billing Address" {...a11yProps(1)} />
                          )}
                        </Tabs>
                      </Box>
                      <CustomTabPanel value={tabValue} index={0}>
                        <Grid container spacing={2} p={1}>
                          <Grid item xs={12}>
                            <BootstrapInput
                              from={
                                fieldError.delivery_Name ? "red" : "#dfcfcf"
                              }
                              name="delivery_Name"
                              fullWidth
                              placeholder="Delivery Name"
                              value={Udata?.delivery_Name}
                              onChange={handleAddressDetails}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <BootstrapInput
                              from={fieldError.ccAdd1 ? "red" : "#dfcfcf"}
                              name="ccAdd1"
                              fullWidth
                              placeholder="Address Line 1"
                              value={Udata?.ccAdd1}
                              onChange={handleAddressDetails}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <BootstrapInput
                              from={"#dfcfcf"}
                              fullWidth
                              name="ccAdd2"
                              placeholder="Address Line 2"
                              value={Udata?.ccAdd2}
                              onChange={handleAddressDetails}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <BootstrapInput
                              from={fieldError.ccTown ? "red" : "#dfcfcf"}
                              fullWidth
                              name="ccTown"
                              placeholder="Town"
                              value={Udata?.ccTown}
                              onChange={handleAddressDetails}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <BootstrapInput
                              from={fieldError.county ? "red" : "#dfcfcf"}
                              fullWidth
                              name="county"
                              placeholder="County"
                              value={Udata?.county}
                              onChange={handleAddressDetails}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Select
                              defaultValue={"Country"}
                              fullWidth
                              labelId="demo-customized-select-label"
                              id="demo-customized-select"
                              placeholder="County"
                              name="country"
                              value={Udata?.country || ""}
                              // onChange={handleChange}
                              input={
                                <BootstrapInput
                                  from={fieldError.country ? "red" : "#dfcfcf"}
                                />
                              }
                              onChange={handleAddressDetails}
                            >
                              {countryNames &&
                                countryNames.map((country, index) => (
                                  <MenuItem
                                    key={index}
                                    value={country?.country}
                                    onClick={() => handleVatChange(country)}
                                  >
                                    {country?.country}
                                  </MenuItem>
                                ))}
                            </Select>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <BootstrapInput
                              from={fieldError.postcode ? "red" : "#dfcfcf"}
                              fullWidth
                              name="postcode"
                              placeholder="Postcode"
                              value={Udata?.postcode}
                              onChange={handleAddressDetails}
                            />
                          </Grid>
                        </Grid>
                      </CustomTabPanel>
                      {!checked && (
                        <CustomTabPanel value={tabValue} index={1}>
                          <Grid container spacing={2} p={1}>
                            <Grid item xs={12}>
                              <BootstrapInput
                                from={
                                  fieldError.billing_Name ? "red" : "#dfcfcf"
                                }
                                name="billing_Name"
                                fullWidth
                                placeholder="Billing Name"
                                value={Udata?.billing_Name}
                                onChange={handleAddressDetails}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <BootstrapInput
                                from={
                                  fieldError.billing_ccAdd1 ? "red" : "#dfcfcf"
                                }
                                name="billing_ccAdd1"
                                fullWidth
                                placeholder="Billing Address Line 1"
                                value={Udata?.billing_ccAdd1}
                                onChange={handleAddressDetails}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <BootstrapInput
                                from={
                                  fieldError.billing_ccAdd2 ? "red" : "#dfcfcf"
                                }
                                fullWidth
                                name="billing_ccAdd2"
                                placeholder="Billing Address Line 2"
                                value={Udata?.billing_ccAdd2}
                                onChange={handleAddressDetails}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <BootstrapInput
                                from={
                                  fieldError.billing_ccTown ? "red" : "#dfcfcf"
                                }
                                fullWidth
                                name="billing_ccTown"
                                placeholder="Billing Town Address"
                                value={Udata?.billing_ccTown}
                                onChange={handleAddressDetails}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <BootstrapInput
                                from={
                                  fieldError.billing_county ? "red" : "#dfcfcf"
                                }
                                fullWidth
                                name="billing_county"
                                placeholder="Billing County address"
                                value={Udata?.billing_county}
                                onChange={handleAddressDetails}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Select
                                defaultValue={"Country"}
                                fullWidth
                                labelId="demo-customized-select-label"
                                id="demo-customized-select"
                                placeholder="County"
                                name="billing_country"
                                value={Udata?.billing_country || ""}
                                // onChange={handleChange}
                                input={
                                  <BootstrapInput
                                    from={
                                      fieldError.billing_country
                                        ? "red"
                                        : "#dfcfcf"
                                    }
                                  />
                                }
                                onChange={handleAddressDetails}
                              >
                                <MenuItem value="" disabled>
                                  Country
                                </MenuItem>
                                {countryNames.map((country, index) => (
                                  <MenuItem
                                    key={index}
                                    value={country?.country}
                                  >
                                    {country?.country}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <BootstrapInput
                                from={
                                  fieldError.billing_postcode
                                    ? "red"
                                    : "#dfcfcf"
                                }
                                fullWidth
                                name="billing_postcode"
                                placeholder="Postcode"
                                value={Udata?.billing_postcode}
                                onChange={handleAddressDetails}
                              />
                            </Grid>
                          </Grid>
                        </CustomTabPanel>
                      )}
                    </Box>

                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        display={"flex"}
                        justifyContent={"center"}
                      >
                        {addressErrors !== null && addressErrors && (
                          <div
                            style={{
                              fontSize: "12px",
                              width: "100%",
                              color: "red",
                              textAlign: "center",
                            }}
                          >
                            {addressErrors}
                          </div>
                        )}
                      </Grid>

                      <Grid item width={"100%"} mb={3}>
                        <Stack
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          spacing
                        >
                          <h6 className="address-above-btn-text">
                            Billing Address:
                          </h6>
                          <FormControlLabel
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            control={
                              <Checkbox
                                onChange={handleChange}
                                label=""
                                defaultChecked
                                sx={{
                                  color: "#e52727",
                                  "&.Mui-checked": {
                                    color: "#e52727",
                                  },
                                }}
                              />
                            }
                            label="Same address as delivery address"
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </div>

                  <Box
                    sx={{
                      background: "#fff",
                      marginTop: "2em",
                      marginBottom: "2em",
                      padding: "1em",
                      borderRadius: "10px",
                    }}
                  >
                    <Stack
                      direction={"row"}
                      width={"100%"}
                      padding={"10px 10px"}
                      justifyContent={"space-around"}
                    >
                      <div className="cart-total-sub-text shipping-details-wrapper">
                        <h3 className="cart-total-main-text">
                          Select shipping type
                        </h3>
                        {loadingShippingDetail ? (
                          <Box
                            sx={{
                              width: "100%",
                              height: "60%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress color={"error"} />
                          </Box>
                        ) : (
                          <Stack>
                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              pl={2}
                            >
                              <input
                                onClick={(e) =>
                                  handleShippingTax(e, shippingService, "d")
                                }
                                name="shipping"
                                className="cart-total-radio-btn"
                                type="radio"
                                value={0}
                                defaultChecked={true}
                                checked={
                                  shippingService.service ===
                                  "FREE - Collection from depot"
                                }
                              />
                              <label className="cart-total-sub-text-price">
                                FREE - Collection from depot{" "}
                                <a href="https://www.ignitioncarparts.co.uk/DeliveryPolicy.aspx">
                                  Click here for details
                                </a>
                              </label>
                            </Stack>
                            {shippingDetails &&
                              shippingDetails.map((d, i) => (
                                <Stack
                                  key={i}
                                  direction={"row"}
                                  alignItems={"center"}
                                  pl={2}
                                >
                                  <input
                                    onClick={(e) => handleShippingTax(e, d)}
                                    name="shipping"
                                    className="cart-total-radio-btn"
                                    type="radio"
                                    value={d?.price}
                                  />
                                  <label className="cart-total-sub-text-price">
                                    {`£${d?.price} - ${d?.service}`}
                                  </label>
                                </Stack>
                              ))}
                          </Stack>
                        )}
                      </div>
                      <div className="cart-cart-total-wrapper shipping-details-wrapper">
                        <Box
                          sx={{
                            width: "100%",
                            height: "auto",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            flexDirection: "column",
                            background: "#fff",
                            boxShadow: "0 1px 3px rgba(0, 0, 0, 0.09);",
                            borderRadius: "10px",
                            paddingBottom: "1em",
                          }}
                        >
                          <h3 className="cart-total-main-text">Cart Totals</h3>

                          <Stack
                            direction={"row"}
                            width={"100%"}
                            padding={"10px 10px"}
                            justifyContent={"space-between"}
                          >
                            <div className="cart-total-sub-text">Subtotal</div>
                            <div className="cart-total-sub-text-price">
                              £{totalPrice.toFixed(2)}
                            </div>
                          </Stack>
                          <Stack
                            direction={"row"}
                            width={"100%"}
                            padding={"10px 10px"}
                            justifyContent={"space-between"}
                            borderBottom={"1px solid #ebebeb"}
                          >
                            <div className="cart-total-sub-text">
                              Product VAT
                            </div>
                            <div className="cart-total-sub-text-price">
                              £{vat.toFixed(2)}
                            </div>
                          </Stack>
                          <Stack
                            direction={"row"}
                            width={"100%"}
                            padding={"10px 10px"}
                            justifyContent={"space-between"}
                            borderBottom={"1px solid #ebebeb"}
                          >
                            <div className="cart-total-sub-text">
                              Shipping Charge
                            </div>
                            <div className="cart-total-sub-text-price">
                              £
                              {(parseFloat(shippingService.price) || 0).toFixed(
                                2
                              )}
                            </div>
                          </Stack>
                          <Stack
                            direction={"row"}
                            width={"100%"}
                            padding={"10px 10px"}
                            justifyContent={"space-between"}
                            borderBottom={"1px solid #ebebeb"}
                          >
                            <div className="cart-total-sub-text">
                              Shipping VAT
                            </div>
                            <div className="cart-total-sub-text-price">
                              £
                              {(
                                parseFloat(shippingService.shippingVAT) || 0
                              ).toFixed(2)}
                            </div>
                          </Stack>

                          <Stack
                            direction={"row"}
                            width={"100%"}
                            padding={"10px 10px"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <div className="cart-total-main-text-bottom ">
                              Total
                            </div>
                            <div className="cart-total-sub-text-price">
                              £{finalPrice || 0}
                            </div>
                          </Stack>
                        </Box>
                      </div>
                    </Stack>
                    <h3 className="address-above-btn-text">
                      Continue you purchase with ...
                    </h3>
                    <Stack
                      direction={"row"}
                      justifyContent={"center"}
                      gap={5}
                      width={"100%"}
                      mt={1}
                      mb={2}
                    >
                      <button
                        className="strip-payment-btn"
                        onClick={() => handleSubmit(Udata, "strip")}
                      >
                        <p className="text-stripe-btn">Pay with Card's</p>
                      </button>

                      <button
                        className="paypal-payment-btn"
                        onClick={() => handleSubmit(Udata, "paypal")}
                      >
                        {PaypalLoading ? (
                          <p className="text-stripe-btn">Loading</p>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="30"
                            viewBox=".02682843 0 123.63183286 30.17842908"
                            width="80"
                          >
                            <path
                              d="m46.211 6.749h-6.839a.95.95 0 0 0 -.939.802l-2.766 17.537a.57.57 0 0 0 .564.658h3.265a.95.95 0 0 0 .939-.803l.746-4.73a.95.95 0 0 1 .938-.803h2.165c4.505 0 7.105-2.18 7.784-6.5.306-1.89.013-3.375-.872-4.415-.972-1.142-2.696-1.746-4.985-1.746zm.789 6.405c-.374 2.454-2.249 2.454-4.062 2.454h-1.032l.724-4.583a.57.57 0 0 1 .563-.481h.473c1.235 0 2.4 0 3.002.704.359.42.469 1.044.332 1.906zm19.654-.079h-3.275a.57.57 0 0 0 -.563.481l-.145.916-.229-.332c-.709-1.029-2.29-1.373-3.868-1.373-3.619 0-6.71 2.741-7.312 6.586-.313 1.918.132 3.752 1.22 5.031.998 1.176 2.426 1.666 4.125 1.666 2.916 0 4.533-1.875 4.533-1.875l-.146.91a.57.57 0 0 0 .562.66h2.95a.95.95 0 0 0 .939-.803l1.77-11.209a.568.568 0 0 0 -.561-.658zm-4.565 6.374c-.316 1.871-1.801 3.127-3.695 3.127-.951 0-1.711-.305-2.199-.883-.484-.574-.668-1.391-.514-2.301.295-1.855 1.805-3.152 3.67-3.152.93 0 1.686.309 2.184.892.499.589.697 1.411.554 2.317zm22.007-6.374h-3.291a.954.954 0 0 0 -.787.417l-4.539 6.686-1.924-6.425a.953.953 0 0 0 -.912-.678h-3.234a.57.57 0 0 0 -.541.754l3.625 10.638-3.408 4.811a.57.57 0 0 0 .465.9h3.287a.949.949 0 0 0 .781-.408l10.946-15.8a.57.57 0 0 0 -.468-.895z"
                              fill="#253b80"
                            />
                            <path
                              d="m94.992 6.749h-6.84a.95.95 0 0 0 -.938.802l-2.766 17.537a.569.569 0 0 0 .562.658h3.51a.665.665 0 0 0 .656-.562l.785-4.971a.95.95 0 0 1 .938-.803h2.164c4.506 0 7.105-2.18 7.785-6.5.307-1.89.012-3.375-.873-4.415-.971-1.142-2.694-1.746-4.983-1.746zm.789 6.405c-.373 2.454-2.248 2.454-4.062 2.454h-1.031l.725-4.583a.568.568 0 0 1 .562-.481h.473c1.234 0 2.4 0 3.002.704.359.42.468 1.044.331 1.906zm19.653-.079h-3.273a.567.567 0 0 0 -.562.481l-.145.916-.23-.332c-.709-1.029-2.289-1.373-3.867-1.373-3.619 0-6.709 2.741-7.311 6.586-.312 1.918.131 3.752 1.219 5.031 1 1.176 2.426 1.666 4.125 1.666 2.916 0 4.533-1.875 4.533-1.875l-.146.91a.57.57 0 0 0 .564.66h2.949a.95.95 0 0 0 .938-.803l1.771-11.209a.571.571 0 0 0 -.565-.658zm-4.565 6.374c-.314 1.871-1.801 3.127-3.695 3.127-.949 0-1.711-.305-2.199-.883-.484-.574-.666-1.391-.514-2.301.297-1.855 1.805-3.152 3.67-3.152.93 0 1.686.309 2.184.892.501.589.699 1.411.554 2.317zm8.426-12.219-2.807 17.858a.569.569 0 0 0 .562.658h2.822c.469 0 .867-.34.939-.803l2.768-17.536a.57.57 0 0 0 -.562-.659h-3.16a.571.571 0 0 0 -.562.482z"
                              fill="#179bd7"
                            />
                            <path
                              d="m7.266 29.154.523-3.322-1.165-.027h-5.563l3.866-24.513a.316.316 0 0 1 .314-.268h9.38c3.114 0 5.263.648 6.385 1.927.526.6.861 1.227 1.023 1.917.17.724.173 1.589.007 2.644l-.012.077v.676l.526.298a3.69 3.69 0 0 1 1.065.812c.45.513.741 1.165.864 1.938.127.795.085 1.741-.123 2.812-.24 1.232-.628 2.305-1.152 3.183a6.547 6.547 0 0 1 -1.825 2c-.696.494-1.523.869-2.458 1.109-.906.236-1.939.355-3.072.355h-.73c-.522 0-1.029.188-1.427.525a2.21 2.21 0 0 0 -.744 1.328l-.055.299-.924 5.855-.042.215c-.011.068-.03.102-.058.125a.155.155 0 0 1 -.096.035z"
                              fill="#253b80"
                            />
                            <path
                              d="m23.048 7.667c-.028.179-.06.362-.096.55-1.237 6.351-5.469 8.545-10.874 8.545h-2.752c-.661 0-1.218.48-1.321 1.132l-1.409 8.936-.399 2.533a.704.704 0 0 0 .695.814h4.881c.578 0 1.069-.42 1.16-.99l.048-.248.919-5.832.059-.32c.09-.572.582-.992 1.16-.992h.73c4.729 0 8.431-1.92 9.513-7.476.452-2.321.218-4.259-.978-5.622a4.667 4.667 0 0 0 -1.336-1.03z"
                              fill="#179bd7"
                            />
                            <path
                              d="m21.754 7.151a9.757 9.757 0 0 0 -1.203-.267 15.284 15.284 0 0 0 -2.426-.177h-7.352a1.172 1.172 0 0 0 -1.159.992l-1.564 9.906-.045.289a1.336 1.336 0 0 1 1.321-1.132h2.752c5.405 0 9.637-2.195 10.874-8.545.037-.188.068-.371.096-.55a6.594 6.594 0 0 0 -1.017-.429 9.045 9.045 0 0 0 -.277-.087z"
                              fill="#222d65"
                            />
                            <path
                              d="m9.614 7.699a1.169 1.169 0 0 1 1.159-.991h7.352c.871 0 1.684.057 2.426.177a9.757 9.757 0 0 1 1.481.353c.365.121.704.264 1.017.429.368-2.347-.003-3.945-1.272-5.392-1.399-1.593-3.924-2.275-7.155-2.275h-9.38c-.66 0-1.223.48-1.325 1.133l-3.907 24.765a.806.806 0 0 0 .795.932h5.791l1.454-9.225z"
                              fill="#253b80"
                            />
                          </svg>
                        )}
                      </button>
                    </Stack>
                  </Box>
                </>
              )}
            </div>
          </>
        )}
      </Container>
    </div>
  );
};

export default CartTable;
