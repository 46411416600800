import {
  Box,
  Container,
  Stack,
  Typography,
  Grid,
  Link,
  Divider,
} from "@mui/material";
import React from "react";
import WaveHeader from "../../../Shared/WaveHeader/WaveHeader";
import Breadcrumb from "../../../Shared/Breadcrumb";

export default function ContactUs() {
  return (
    <Box
      sx={{
        background: "#fafafa",
        padding: {
          xl: "12px 5em ",
          lg: "12px 5em ",
          sm: "12px 3em ",
          xs: "12px 1em ",
        },
        zIndex: "-1",
      }}
    >
      <Stack direction={"row"}>
        <Breadcrumb text={"Home"} />
        <Breadcrumb text={"Contact Us"} />
      </Stack>
      <Container
        maxWidth={"xl"}
        style={{
          height: "auto",
          marginTop: "10px",
          background: "#fff",
          padding: "0px",
          paddingBottom: "1px",
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(228, 228, 15, 0.15) 0px 0px 0px 1px",
        }}
      >
        <WaveHeader title={"Contact Us"} type={"contactus"} />
        <Container
          maxWidth="lg"
          sx={{
            padding: {
              lg: "30px",
              xl: "40px",
              sm: "15px",

              sm: "10px",
            },
          }}
        >
          <Typography variant="h2">Ignition Car Parts Ltd</Typography>
          <Typography variant="h5">
            Unit 16, Ilford Trading Estate, Paycocke Road, BASILDON, Essex SS14
            3DR
          </Typography>
          <Box sx={{ my: 2 }}>
            <Divider />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h5">Opening Hours:</Typography>
              <Typography>Monday to Friday: 9am - 5pm</Typography>
              <Typography>Closed for Lunch: 12pm - 1pm</Typography>
              <Typography>Closed on Bank Holidays</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h5">Contact:</Typography>
              <Typography>
                Phone Sales: <Link href="tel:01268857880">01268 857 880</Link>
              </Typography>
              <Typography>
                Skype: <Link href="skype:02081230124?call">0208 123 0124</Link>
              </Typography>
              <Typography>
                Email:{" "}
                <Link href="mailto:Sales@ignitioncarparts.co.uk">
                  Sales@ignitioncarparts.co.uk
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </Box>
  );
}
