import React from "react";
import { useNavigate } from "react-router-dom";
import "./AdminAlert.css";
import { useDispatch } from "react-redux";
import { GetUserLogin } from "../../../Redux/Reducers/reducerSlices/LoginSlices/LoginSlice";

export default function AdminAlert() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleAdminLogin = () => {
    let d = JSON.parse(localStorage.getItem("admin"));
    dispatch(GetUserLogin({ email: d.email, password: d.password }));
    localStorage.removeItem("parentUser");
    localStorage.removeItem("admin");
  };
  return (
    <div style={{ borderBottom: "1px solid #ebebeb", background: "#282828" }}>
      <div style={{ margin: "0 65px" }}>
        <div className="d-flex flex-row justify-content-between  p-1  ">
          <div className="row gap-2 " style={{ width: "100%" }}>
            <p className="alert-text">You have logged in as a other user...</p>
          </div>
          <div className="d-flex flex-row" style={{ width: "100%" }}>
            <p
              className="alert-text"
              style={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#e52727",
                cursor: "pointer",
              }}
              onClick={handleAdminLogin}
            >
              Go back to your account
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
