import { Box, Container, Stack, Typography, Paper, Link } from "@mui/material";
import React from "react";
import WaveHeader from "../../../Shared/WaveHeader/WaveHeader";
import Breadcrumb from "../../../Shared/Breadcrumb";
import PageTitles from "../../../Shared/PageTitles";

export default function AboutUs() {
  return (
    <Box
      sx={{
        background: "#fafafa",
        padding: {
          xl: "12px 5em ",
          lg: "12px 5em ",
          sm: "12px 3em ",
          xs: "12px 1em ",
        },
        zIndex: "-1",
      }}
    >
      {" "}
      <Stack direction={"row"}>
        <Breadcrumb text={"Home"} />
        <Breadcrumb text={"About Us"} />
      </Stack>
      <Container
        maxWidth={"xl"}
        style={{
          marginTop: "10px",
          height: "auto",
          background: "#fff",
          padding: "0px",
          paddingBottom: "1px",
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(228, 228, 15, 0.15) 0px 0px 0px 1px",
        }}
      >
        <WaveHeader title={"About Us"} type={"aboutus"} />
        <PageTitles title={"Welcome to ICP LTD"} />
        <Container maxWidth="lg">
          <Paper elevation={2} sx={{ p: 3, mb: 4 }}>
            <Typography variant="body1" paragraph>
              The home for New, Reconditioned, and Used distributors, caps,
              rotors, Modules, coils, Points, Condensers for your classic car,
              Van, Boat, Tractor, etc.
            </Typography>
            <Typography variant="body1" paragraph>
              Ignition Car Parts Ltd are one of the largest suppliers of new
              auto parts in the UK, established 30 years ago. We now have 6
              full-time staff at our location in Basildon, Essex. We can supply
              Bosch, Delphi, Facet, Ntk, Ngk, Blueprint, Beru, Kerr Nelson,
              Standard, SMP, Intermotor, Lucas, Bremi, Hella, Siemens, VDO, and
              a full range of aftermarket parts.
            </Typography>
            <Typography variant="body1" paragraph>
              Ignition coils, modules, caps, rotors, distributors, Timing
              sensors, Cam crankshaft and Knock sensors, Throttle Bodies
              position sensors, lambda, air temp oil temp pressure sensors,
              airflow meters, air sensors, etc.
            </Typography>
            <Typography variant="body1" paragraph>
              We build new and reconditioned distributors, we can test and
              rebuild any distributor, and we have reconditioned distributors
              for tractors, speed boats, airplanes, track cars, buggies, race
              cars, classic cars, American cars, just about anything you name it
              we have done it.
            </Typography>
            <Typography variant="body1" paragraph>
              We can test any distributor; we do this free. Repairs from £10 +
              parts. We can also test any coil or ignition module. If you are
              sending in a part for repair,{" "}
              <Link
                href="https://www.ignitioncarparts.co.uk/ProductImage.aspx?img=repairform.jpg"
                target="_blank"
              >
                click here
              </Link>
              .
            </Typography>
            <Typography variant="body1" paragraph>
              If you want to use our Free testing service, call Tel 01268 857880
              or email{" "}
              <Link href="mailto:sales@ignitioncarparts.co.uk">
                sales@ignitioncarparts.co.uk
              </Link>
              .
            </Typography>
            <Typography variant="body1" paragraph>
              IGNITION CAR PARTS LTD, Unit 16 Ilford Trading Estate, Paycocke
              Road, Basildon, Essex, SS14 3DR | Tel: 01268 857 880
            </Typography>
          </Paper>
        </Container>
      </Container>
    </Box>
  );
}
