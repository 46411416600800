import React from "react";
import "./PaymentSuccess.css";
import { useSelector } from "react-redux";
import { Box, Card, CardHeader, Container, Stack } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../Shared/Breadcrumb";
import WaveHeader from "../../Shared/WaveHeader/WaveHeader";

export default function PaymentSuccess() {
  const { checkoutProducts, stripPI_ID, orderID } = useSelector(
    (state) => state.order
  );
  // console.log(orderID);
  const navigate = useNavigate();
  return (
    <div>
      <Box
        sx={{
          background: "#fafafa",
          padding: {
            xl: "12px 5em ",
            lg: "12px 5em ",
            sm: "12px 3em ",
            xs: "12px 1em ",
          },
          zIndex: "-1",
        }}
      >
        <Stack direction={"row"}>
          <Breadcrumb text={"Home"} />
          <Breadcrumb text={"Payment Successful"} />
        </Stack>
        <Container
          maxWidth={"xl"}
          style={{
            marginTop: "10px",
            height: "auto",
            background: "#fff",
            padding: "0px",
            paddingBottom: "1px",
            boxShadow:
              "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(228, 228, 15, 0.15) 0px 0px 0px 1px",
          }}
        >
          <WaveHeader title={"Order Placed!"} type={"Order"} />
          <Card
            sx={{
              sm: { marginTop: "10px", padding: "10px" },
              md: { marginTop: "20px", padding: "10px" },
              lg: { marginTop: "20px", padding: "10px" },
            }}
          >
            <CardHeader
              title="Your Order has been placed successfully..!"
              style={{
                fontFamily: '"Roboto", "sans-serif"',
              }}
              subheader={`Order ID : ${orderID}`}
            />
            <Typography
              sx={{ fontSize: 14, padding: "20px" }}
              color="text.primary"
            >
              Date :{checkoutProducts[0]?.paidDate.split("T")[0]}
            </Typography>
            <Typography
              sx={{ fontSize: 14, padding: "20px" }}
              color="text.primary"
            >
              Token : {stripPI_ID}
            </Typography>

            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="body2" color="text.secondary">
                Your Order has been placed successfully and will be delivered
                soon to your given deliery a ddress. For any kind of queries
                please raise a issue ticket with the given token.
              </Typography>
              <button
                className="Home-btn"
                onClick={() => {
                  navigate("/MyOrders");
                }}
              >
                My Orders
              </button>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </div>
  );
}
// PaymentSuccess {stripPI_ID}
