import React from "react";
import { useDispatch } from "react-redux";
import { updateQuantity } from "../../Redux/Reducers/reducerSlices/AddToCartSlice/AddtoCartSlice";

export default function QuantityByn({ qty, product, disabled }) {
  const handleUpdateQuantity = (productId, newQuantity) => {
    dispatch(updateQuantity({ productId, quantity: newQuantity }));
  };

  const dispatch = useDispatch();

  return (
    <div className="card-add-sub">
      {!disabled && (
        <button
          onClick={() =>
            handleUpdateQuantity(product.productcode, product.quantity - 1)
          }
          disabled={product.quantity <= 1}
          className="card-add-sub-btn"
        >
          -
        </button>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "40px",
          width: "100%",
        }}
      >
        {qty}
      </div>
      {!disabled && (
        <button
          onClick={() =>
            handleUpdateQuantity(product.productcode, product.quantity + 1)
          }
          className="card-add-sub-btn"
        >
          +
        </button>
      )}
    </div>
  );
}
