import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { BASE_URL } from "../../../Constants/Constants";
import { Box, Container, Stack } from "@mui/material";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  sendOrder,
  SetstripPI_ID,
} from "../../../Redux/Reducers/reducerSlices/ChechOutSlice/OrderSlice";
import { useNavigate } from "react-router-dom";
import CreditCardPayment from "../../../svg/CreditCardPayment.gif";
import Breadcrumb from "../../Shared/Breadcrumb";
import { clearCart } from "../../../Redux/Reducers/reducerSlices/AddToCartSlice/AddtoCartSlice";
export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { checkoutProducts, totalAmount, orderID } = useSelector(
    (state) => state.order
  );
  // const price = JSON.parse(localStorage.getItem("FP"));
  // console.log(checkoutProducts);
  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      BASE_URL + "create-intent"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          toast.success("Payment succeeded!");
          break;
        case "processing":
          toast.success("Your payment is processing.");
          break;
        case "requires_payment_method":
          toast.success("Your payment was not successful, please try again.");
          break;
        default:
          toast.success("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(elements);
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      // console.log("errorrr");

      return;
    }

    setIsLoading(true);
    const res = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `http://localhost:3000/PaymentSuccess`,
      },
      redirect: "if_required",
    });
    // console.log(res);

    if (!res.error && res?.paymentIntent) {
      const mappedProducts = [
        {
          executionType: 2,
          orderNo: orderID[0],
          productCode: checkoutProducts[0]?.productcode,
          quantity: checkoutProducts[0]?.quantity,
          customerID: checkoutProducts[0]?.customerID || 0,
          delivery_Name: checkoutProducts[0]?.delivery_Name,
          delivery_Add1: checkoutProducts[0]?.delivery_Add1,
          delivery_Add2: checkoutProducts[0]?.delivery_Add2,
          delivery_Town: checkoutProducts[0]?.delivery_Town,
          delivery_County: checkoutProducts[0]?.delivery_County,
          delivery_Postcode: checkoutProducts[0]?.delivery_Postcode,
          delivery_Country: checkoutProducts[0]?.delivery_Country,
          cC_Name: checkoutProducts[0]?.cC_Name,
          cC_Add1: checkoutProducts[0]?.cC_Add1,
          cC_Add2: checkoutProducts[0]?.cC_Add2,
          cC_Town: checkoutProducts[0]?.cC_Town,
          cC_County: checkoutProducts[0]?.cC_County,
          cC_Postcode: checkoutProducts[0]?.cC_Postcode,
          cC_Country: checkoutProducts[0]?.cC_Country,
          perUnitPrice: checkoutProducts[0]?.perUnitPrice,
          perUnitVAT: checkoutProducts[0]?.perUnitVAT,
          shippingBand: "",
          shippingName: checkoutProducts[0]?.courierName,
          shippingNett: checkoutProducts[0]?.shippingNett,
          shippingVAT: checkoutProducts[0]?.shippingVAT,
          status: "Shopping Cart",
          date: checkoutProducts[0]?.date,
          supplierCode: null,
          paymentMethod: "Card",
          note: null,
          customerOrderNo: null,
          issueNumber: 0,
          headOffice: 0,
          companyName: null,
          paidDate: new Date().toISOString(),
          ePDQ_LastTransactionType: "none",
          ePDQ_AuthCode: res?.paymentIntent?.id,
          orderTime: checkoutProducts[0]?.orderTime,
          orderedBy: "",
          surcharge: 0.0,
          handlingCharge: 0.0,
          numberSold: 0,
          trackingNo: null,
          courierName: checkoutProducts[0]?.courierName,
          courierCollectionDate: new Date().toISOString(), //temporary, value needs to be updated later
          courierCollectionDeliveryDate: new Date().toISOString(), //temporary, value needs to be updated later
          courierCollectionReference: "",
          orderType: "order",
          options: "",
          vehicleID: 0,
        },
      ];
      dispatch(sendOrder(mappedProducts)).then(() => {
        navigate("/PaymentSuccess");
        dispatch(clearCart());
      });
      dispatch(SetstripPI_ID(res?.paymentIntent?.id));
    }

    if (
      res.error?.type === "card_error" ||
      res.error?.type === "validation_error" ||
      res.error
    ) {
      // console.log(res?.error?.message);
      setMessage(res.error.message);
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <Box
      sx={{
        background: "#fafafa",
        padding: {
          xl: "12px 5em ",
          lg: "12px 5em ",
          sm: "12px 3em ",
          xs: "12px 1em ",
        },
        zIndex: "-1",
      }}
    >
      {" "}
      <Stack direction={"row"}>
        <Breadcrumb text={"Home"} />
        <Breadcrumb text={"Shopping Cart"} />
        <Breadcrumb text={"Card Payment"} />
      </Stack>
      <Container
        maxWidth={"xl"}
        style={{
          marginTop: "10px",
          height: "auto",
          background: "#fff",
          padding: "0px",
          paddingBottom: "1px",
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(228, 228, 15, 0.15) 0px 0px 0px 1px",
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          flexDirection: {
            xs: "column",
            md: "column",
            lg: "row",
            xl: "row",
          },
        }}
      >
        <div className="hero-wrapper">
          <img
            className="gif-wrapper"
            src={CreditCardPayment}
            alt="environment-based gif"
          />
          <a style={{ fontSize: "8px" }} href="https://storyset.com/online">
            Online illustrations by Storyset
          </a>
        </div>
        <form id="payment-form" onSubmit={handleSubmit}>
          {/* <p className="total-amount-text">Total :£{totalAmount || price}</p> */}
          <p className="total-amount-text">Total :£{totalAmount}</p>
          <PaymentElement
            id="payment-element"
            options={paymentElementOptions}
          />
          <button disabled={isLoading || !stripe || !elements} id="submit">
            <span id="button-text">
              {isLoading ? (
                <div className="spinner" id="spinner"></div>
              ) : (
                "Pay now"
              )}
            </span>
          </button>
          {/* Show any error or success messages */}
          {message && <div id="payment-message">{message}</div>}
        </form>
      </Container>
    </Box>
  );
}
