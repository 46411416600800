import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../../../Constants/Constants";
const initialState = {
  isLoading: false,
  data: [],
  details: [],
  error: "",
  isLoadingForDetails: false,
};

export const GetMyOrders = createAsyncThunk(
  "MyOrders/GetMyOrders",
  async ({ userID, type }, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        `${BASE_URL}GetOrderDetailsbyUserID?CustomerID=${userID}&ExecutionType=${type}`
      );
      return { data: res.data.value, type };
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

const MyOrdersSlice = createSlice({
  name: "MyOrders",
  initialState,
  reducers: {
    orderCleanUp: (state) => {
      state.data = [];
    },
  },
  //GetMyOrders
  extraReducers: (builder) => {
    builder.addCase(GetMyOrders.fulfilled, (state, action) => {
      // console.log(action.payload);
      if (action.payload.type === 1) state.data = action.payload.data;
      if (action.payload.type === 2) state.details = action.payload.data;
      state.isLoading = false;
      state.isLoadingForDetails = false;
    });
    builder.addCase(GetMyOrders.rejected, (state, action) => {
      state.isLoading = false;
      state.isLoadingForDetails = false;
      state.error = action.payload;
      toast.error(action.payload);
    });
    builder.addCase(GetMyOrders.pending, (state, action) => {
      if (action.meta.arg.type === 1) {
        state.isLoading = true;
      }
      if (action.meta.arg.type === 2) {
        state.isLoadingForDetails = true;
      }
    });
  },
});
export const { orderCleanUp } = MyOrdersSlice.actions;
export default MyOrdersSlice.reducer;
