import React, { useEffect, useState } from "react";
import { BootstrapInput } from "../../../Themes/Theme";
import {
  Box,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import WaveHeader from "../../Shared/WaveHeader/WaveHeader";
import "../PopOverComponent.css";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { createUser } from "../../../Redux/Reducers/reducerSlices/LoginSlices/LoginSlice";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { getCountryDetails } from "../../../Redux/Reducers/reducerSlices/CountrySlice/CountrySlice";
import Loader from "../../Shared/Loader";
import Breadcrumb from "../../Shared/Breadcrumb";
export default function RegisterForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [registerData, SetRegisterData] = useState({
    id: 0,
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    tel: "",
    mob: "",
    add1: "",
    add2: "",
    town: "",
    county: "",
    postcode: "",
    country: "",
    ccAdd1: "",
    ccAdd2: "",
    ccTown: "",
    ccCounty: "",
    ccPostcode: "",
    ccCountry: "",
    accountType: "",
    paymentType: "",
    headOffice: 0,
    chargeVAT: 0,
    companyName: "",
    fax: "",
    vatNumber: "",
    accountPeriod: 0,
    catalogueAccess: "",
  });
  const [repass, setRePass] = useState({
    rePassValue: "",
    repassErr: false,
  });
  const [fieldError, setFieldError] = useState({
    add1: false,
    email: false,
    emailValid: false,
    firstName: false,
    lastName: false,
    postcode: false,
    password: false,
    town: false,
    country: false,
  });
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const {
    countryNames,
    shippingDetails,
    loadingCountryDetails,
    loadingShippingDetail,
  } = useSelector((state) => state.Country);
  // console.log(registerData);
  const handleRegister = (e) => {
    const { name, value } = e.target;
    SetRegisterData({ ...registerData, [name]: value });
    setFieldError({ ...fieldError, [name]: false, emailValid: false });
    setRePass({ ...repass, repassErr: false });
  };
  // console.log(fieldError);
  useEffect(() => {
    dispatch(getCountryDetails());
  }, []);
  // console.log(registerData);
  const handleSubmitValidation = () => {
    let flag = true;
    if (
      registerData?.firstName === "" ||
      registerData?.firstName?.length === 0
    ) {
      flag = false;
      setFieldError((prevFieldError) => ({
        ...prevFieldError,
        firstName: true,
      }));
    }
    if (registerData?.lastName === "" && registerData?.lastName?.length === 0) {
      flag = false;
      setFieldError((prevFieldError) => ({
        ...prevFieldError,
        lastName: true,
      }));
    }
    if (registerData?.add1 === "" && registerData?.add1?.length === 0) {
      flag = false;
      setFieldError((prevFieldError) => ({
        ...prevFieldError,
        add1: true,
      }));
    }
    if (registerData?.email === "" && registerData?.email?.length === 0) {
      flag = false;
      setFieldError((prevFieldError) => ({
        ...prevFieldError,
        email: true,
      }));
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!registerData?.email.match(emailRegex)) {
      flag = false;
      setFieldError((prevFieldError) => ({
        ...prevFieldError,
        emailValid: true,
      }));
    }
    if (registerData?.password === "" && registerData?.password?.length === 0) {
      flag = false;
      setFieldError((prevFieldError) => ({
        ...prevFieldError,
        password: true,
      }));
    }

    if (registerData?.town === "" && registerData?.town?.length === 0) {
      flag = false;
      setFieldError((prevFieldError) => ({
        ...prevFieldError,
        town: true,
      }));
    }
    if (registerData?.postcode === "" && registerData?.postcode?.length === 0) {
      flag = false;
      setFieldError((prevFieldError) => ({
        ...prevFieldError,
        postcode: true,
      }));
    }
    if (registerData?.country === "" && registerData?.country?.length === 0) {
      flag = false;
      setFieldError((prevFieldError) => ({
        ...prevFieldError,
        country: true,
      }));
    }
    if (repass.rePassValue === "") {
      flag = false;
      setRePass((prev) => ({
        ...prev,
        repassErr: true,
      }));
    }
    if (repass.rePassValue !== registerData.password) {
      flag = false;
      setRePass((prev) => ({
        ...prev,
        repassErr: true,
      }));
    }
    // console.log(flag);
    return flag;
  };

  const handleSubmit = () => {
    // console.log(handleSubmitValidation());
    if (handleSubmitValidation()) {
      dispatch(createUser({ registerData })).then(() => {
        navigate("/");
      });
    }
  };

  const errorDiv = (field) => {
    if (field === "unmatch")
      return <p className="error-div">Password doesn't match</p>;
    if (field === "emailnotvalid")
      return <p className="error-div">Please enter valid email</p>;
    return <p className="error-div">{field} is Mandatory</p>;
  };
  // console.log(isValid);
  return (
    <Box
      sx={{
        background: "#fafafa",
        padding: {
          xl: "12px 5em ",
          lg: "12px 5em ",
          sm: "12px 3em ",
          xs: "12px 1em ",
        },
        zIndex: "-1",
      }}
    >
      {" "}
      <Stack direction={"row"}>
        <Breadcrumb text={"Home"} />
        <Breadcrumb text={"Register"} />
      </Stack>
      <Container
        maxWidth={"xl"}
        style={{
          marginTop: "10px",
          height: "auto",
          background: "#fff",
          padding: "0px",
          paddingBottom: "1px",
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(228, 228, 15, 0.15) 0px 0px 0px 1px",
        }}
      >
        <WaveHeader title={"Register"} type={"Register"} />
        {loadingCountryDetails ? (
          <Loader />
        ) : (
          <>
            <Box
              sx={{
                boxShadow:
                  "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(228, 228, 15, 0.15) 0px 0px 0px 1px",
                backgroundColor: "#fff",
                borderRadius: "3px",
                background: "#fff",
              }}
              m={4}
              p={2}
            >
              <Typography textAlign={"center"} variant="h6">
                New Customer Details
              </Typography>
              <Stack direction={"column"}>
                <Typography textAlign={"center"} variant="p" fontSize={"10px"}>
                  If you already hold an account with us please login prior to
                  visiting the checkout.
                </Typography>
                <Typography textAlign={"center"} variant="p" fontSize={"10px"}>
                  If you wish to create a new account please continue by
                  providing the information below.
                </Typography>
              </Stack>
              <Grid container spacing={2} p={1} pt={2}>
                <Grid item xs={12} sm={6}>
                  <BootstrapInput
                    from={fieldError.firstName ? "red" : "#dfcfcf"}
                    name="firstName"
                    fullWidth
                    placeholder="First Name"
                    value={registerData?.firstName}
                    onChange={handleRegister}
                  />
                  {fieldError.firstName && errorDiv("First Name")}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <BootstrapInput
                    from={fieldError.firstName ? "red" : "#dfcfcf"}
                    name="lastName"
                    fullWidth
                    placeholder="Last Name"
                    value={registerData?.lastName}
                    onChange={handleRegister}
                  />
                  {fieldError.firstName && errorDiv("Last Name")}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <BootstrapInput
                    from={"#dfcfcf"}
                    fullWidth
                    type="number"
                    name="tel"
                    placeholder="Landline Tel"
                    value={registerData?.tel}
                    onChange={handleRegister}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <BootstrapInput
                    from={"#dfcfcf"}
                    fullWidth
                    name="fax"
                    placeholder="Fax"
                    value={registerData?.fax}
                    onChange={handleRegister}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <BootstrapInput
                    from={"#dfcfcf"}
                    fullWidth
                    name="mob"
                    placeholder="Mobile Tel"
                    value={registerData?.mob}
                    onChange={handleRegister}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <BootstrapInput
                    fullWidth
                    name="email"
                    placeholder="Email Address"
                    value={registerData?.email}
                    onChange={handleRegister}
                    from={fieldError.email ? "red" : "#dfcfcf"}
                  />
                  {fieldError.email && errorDiv("Email")}
                  {fieldError.emailValid && errorDiv("emailnotvalid")}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <BootstrapInput
                    type={showPassword1 ? "text" : "password"}
                    fullWidth
                    name="password"
                    placeholder="New Password"
                    value={registerData?.password}
                    onChange={handleRegister}
                    from={fieldError.password ? "red" : "#dfcfcf"}
                    endAdornment={
                      <InputAdornment position="center">
                        <IconButton
                          onClick={() => setShowPassword1(!showPassword1)}
                          edge="end"
                        >
                          {showPassword1 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {fieldError.password && errorDiv("Password")}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <BootstrapInput
                    type={showPassword2 ? "text" : "password"}
                    fullWidth
                    name="repass"
                    placeholder="Re-enter Password"
                    value={repass.rePassValue}
                    onChange={(e) =>
                      setRePass({ ...repass, rePassValue: e.target.value })
                    }
                    from={repass.repassErr ? "red" : "#dfcfcf"}
                    endAdornment={
                      <InputAdornment position="center">
                        <IconButton
                          onClick={() => setShowPassword2(!showPassword2)}
                          edge="end"
                        >
                          {showPassword2 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {repass.repassErr && errorDiv("unmatch")}
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                boxShadow:
                  "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(228, 228, 15, 0.15) 0px 0px 0px 1px",
                backgroundColor: "#fff",
                borderRadius: "3px",
              }}
              m={4}
              p={2}
            >
              <Typography textAlign={"center"} variant="h6">
                Account Address
              </Typography>

              <Grid container spacing={2} p={1} pt={2}>
                <Grid item xs={12} sm={6}>
                  <BootstrapInput
                    fullWidth
                    name="postcode"
                    placeholder="Postcode"
                    value={registerData?.postcode}
                    onChange={handleRegister}
                    from={fieldError.postcode ? "red" : "#dfcfcf"}
                  />
                  {fieldError.postcode && errorDiv("postcode")}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <BootstrapInput
                    fullWidth
                    name="add1"
                    placeholder="Address Line 1"
                    value={registerData?.add1}
                    onChange={handleRegister}
                    from={fieldError.add1 ? "red" : "#dfcfcf"}
                  />
                  {fieldError.add1 && errorDiv("Address")}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <BootstrapInput
                    from={"#dfcfcf"}
                    fullWidth
                    name="add2"
                    placeholder="Address Line 2"
                    value={registerData?.add2}
                    onChange={handleRegister}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <BootstrapInput
                    fullWidth
                    name="town"
                    placeholder="Town"
                    value={registerData?.town}
                    onChange={handleRegister}
                    from={fieldError.town ? "red" : "#dfcfcf"}
                  />
                  {fieldError.town && errorDiv("Town")}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <BootstrapInput
                    from={"#dfcfcf"}
                    fullWidth
                    name="county"
                    placeholder="county"
                    value={registerData?.county}
                    onChange={handleRegister}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Select
                    fullWidth
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    name="country"
                    value={registerData?.country}
                    input={
                      <BootstrapInput
                        from={fieldError.country ? "red" : "#dfcfcf"}
                      />
                    }
                    onChange={handleRegister}
                  >
                    {countryNames.map((country, index) => (
                      <MenuItem key={index} value={country?.country}>
                        {country.country}
                      </MenuItem>
                    ))}
                  </Select>
                  {fieldError.country && errorDiv("Country")}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <BootstrapInput
                    from={"#dfcfcf"}
                    fullWidth
                    name="companyName"
                    placeholder="Company Name"
                    value={registerData?.companyName}
                    onChange={handleRegister}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <BootstrapInput
                    from={"#dfcfcf"}
                    fullWidth
                    name="vat"
                    placeholder="VAT Registration No"
                    value={registerData?.vat}
                    onChange={handleRegister}
                  />
                </Grid>
              </Grid>
              <Grid p={2} display={"flex"} justifyContent={"center"}>
                <button onClick={handleSubmit} className={"cart-checkout-btn"}>
                  Register
                </button>
              </Grid>
            </Box>
          </>
        )}
      </Container>
    </Box>
  );
}

/*
First Name:	 	 
Last Name:	
Landline Tel:	
Fax	:  
Mobile Tel:	  
Email Address:	   
New Password:	  
Re-enter Password:	   
Account Address
Postcode:	   
btn ==> Lookup	 
Address Line 1:	   
Address Line 2:	
Town:	
County:	
Country:	
Company Name:	
VAT Registration No:



Town has not been entered
Address line 1 has not been entered
Postcode has not been entered
Password must be 6 or more characters
Email address has not been entered
Last Name has not been entered
First Name has not been entered

*/
