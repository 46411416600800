import React from "react";
import { useNavigate } from "react-router-dom";
// MUI Imports
import Stack from "@mui/material/Stack";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
//css

import "./NavBarMobile.css";

export default function DrawerMenu({ toggleDrawer }) {
  const navigate = useNavigate();
  return (
    <div
      className="Drawer-menu-hero"
      style={{
        height: "100%",
        padding: "0 10px",
        marginTop: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Stack direction="row" gap={10}>
        <Stack direction="row" gap={2}>
          <span className="English-span">EN</span>
          <div>
            <p className="English-Text">English</p>
          </div>
        </Stack>
        <Stack direction="row" gap={2}>
          <span className="English-span">EN</span>
          <div>
            <p className="English-Text">English</p>
          </div>
        </Stack>
      </Stack>
      <div className="buttom-border-div"></div>
      <Stack direction="row" p="0 10px" mt={1} gap={6}>
        <Stack direction="column">
          <div style={{ alignSelf: "center" }}>
            <PermIdentityIcon />
          </div>
          <p className="Drawer-menu--items">Account</p>
        </Stack>
        <Stack direction="column">
          <div style={{ alignSelf: "center" }}>
            <FavoriteBorderIcon />
          </div>
          <p className="Drawer-menu--items">Wishlist</p>
        </Stack>
        <Stack direction="column">
          <div style={{ alignSelf: "center" }}>
            <ShoppingCartOutlinedIcon />
          </div>
          <p
            className="Drawer-menu--items"
            onClick={() => {
              navigate("/CartDisplay");
              toggleDrawer();
            }}
          >
            Cart
          </p>
        </Stack>
      </Stack>
      <div className="buttom-border-div"></div>
      <Stack direction="column" justifyContent="flex-start">
        <Stack direction="row" gap={22.5} mt={2}>
          <div>
            <p>English</p>
          </div>
          <ArrowForwardIosIcon />
        </Stack>
        <Stack direction="row" gap={24} mt={2}>
          <div>
            <p>Shop</p>
          </div>
          <ArrowForwardIosIcon />
        </Stack>
        <Stack direction="row" gap={21.5} mt={2}>
          <div>
            <p>Account</p>
          </div>
          <ArrowForwardIosIcon />
        </Stack>
        <Stack direction="row" gap={21.5} mt={2}>
          <div
            onClick={() => {
              navigate("/ProductView");
            }}
          >
            <p>Products</p>
          </div>
          <ArrowForwardIosIcon />
        </Stack>

        <div className="buttom-contact">
          <div>Free call 24/7</div>
          <div>800 060-0730</div>
        </div>
      </Stack>
    </div>
  );
}
