import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// MUI Imports

import Badge from "@mui/material/Badge";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { useMediaQuery } from "@mui/material";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Popover from "@mui/material/Popover";

// svg
import menuSvg from "../../../../svg/menu-16x12.svg";
// css
import "../NavBar-Desktop/NavBar.css";
import RedPartLogoSVGMobile from "../../../../svg/RedPartLogoSVGMobile";
import DrawerMenu from "./DrawerMenu";
import { ColorButtonYellow } from "../../../../Themes/Theme";
import Login from "../../../Account/Login/Login";
import {
  GetManufacturerList,
  GetProductDetails,
  GetProductWithCode,
} from "../../../../Redux/Reducers/reducerSlices/ProductsSlices/ProductDetailsSlice";

export default function NavBarMobile() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [show, setShow] = useState(false);
  const [type, setType] = useState("0");
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("1");
  const productsData = useSelector((state) => state.ProductDetails.data);
  const isLoading = useSelector((state) => state.ProductDetails.isLoading);
  const userData = useSelector((state) => state.login.data[0]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (anchorEl && !anchorEl.contains(event.target)) {
        handleClose();
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [anchorEl]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const isMediumScreen = useMediaQuery("(min-width:900px)");
  const isextraSmallScreen = useMediaQuery("(max-width:280px)");
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prev) => !prev);
  };
  const handleSelect = (e) => {
    if (e.target.value === "1") {
      setType("1");
    } else if (e.target.value === "2") {
      setType("2");
    } else if (e.target.value === "3") {
      setType("3");
    } else if (e.target.value === "4") {
      setShow(true);
      dispatch(GetManufacturerList());
      setType("4");
    } else {
      setSearchTerm("");
    }
  };
  const filteredProducts = productsData
    ? productsData.filter(
        (product) =>
          product.partManufacturer
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          product.productcode
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          product.manufacturer?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  const handleSuggestionClick = (value) => {
    setSuggestions(value);
    setShow(false);
    setSearchTerm(value.manufacturer);
    // console.log(value);
  };

  const search = () => {
    if (type === "1") {
    } else if (type === "2") {
      dispatch(
        GetProductWithCode({
          SearchInput: searchTerm,
          userID: userData?.id || null,
        })
      );
    } else if (type === "3") {
    } else if (type === "4") {
      dispatch(
        GetProductDetails({
          SearchInput: suggestions,
          type: 4,
          userID: userData?.id || null,
        })
      );
    }
    setType("0");
  };
  return (
    <div style={{ margin: isMediumScreen ? "0 80px" : "0 40px" }}>
      <div style={{ height: "50px" }}>
        <div
          className="row"
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <div onClick={() => toggleDrawer()} style={{ display: "flex" }}>
              <img
                alt="menu"
                style={{ alignSelf: "center", height: "20px" }}
                src={menuSvg}
              />
            </div>
          </div>
          <div
            onClick={() => {
              navigate("/");
            }}
            className={isMediumScreen ? "col-2" : "col"}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignContent: "center",
            }}
          >
            <div style={{ alignSelf: "center" }}>
              <RedPartLogoSVGMobile />
            </div>
          </div>
          {isMediumScreen && (
            <div className="col-7" style={{ display: "flex", flexGrow: "1" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  width: "100%",
                }}
              >
                <select
                  value={type}
                  onChange={(e) => {
                    handleSelect(e);
                  }}
                  className="NBM-dropdown"
                >
                  <option selected value={0}>
                    Search By...
                  </option>
                  <option value={1}>Everything</option>
                  <option value={2}>Manufacturers Part Code</option>
                  <option value={3}>Engine code</option>
                  <option value={4}>Manufacturer</option>
                </select>

                <input
                  disabled={isLoading}
                  style={{ marginLeft: "20px", marginRight: "20px" }}
                  className="NBM-Search-Box" //NBM refers to Nav bar mid
                  placeholder={
                    isLoading ? "Loading..." : "Enter Keyword or Part Number"
                  }
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                {searchTerm && show && (
                  <ul className="autosuggest-list">
                    {filteredProducts.map((suggestion, index) => (
                      <li
                        className="suggestion-item"
                        key={index}
                        onClick={(e) => handleSuggestionClick(suggestion)}
                        style={{ cursor: "pointer", padding: "5px" }}
                      >
                        {suggestion?.productcode}
                        {suggestion?.partManufacturer}
                        {suggestion?.manufacturer}
                      </li>
                    ))}
                  </ul>
                )}
                <ColorButtonYellow
                  onClick={search}
                  className="NBM-ColorButtonText"
                  startIcon={<DirectionsCarIcon />}
                >
                  Search
                </ColorButtonYellow>
              </div>
            </div>
          )}
          <div className="col-2.5" style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "100%",
                }}
              >
                <div className="NBM-Div-Hover">
                  <Badge
                    sx={{
                      "& .MuiBadge-badge": {
                        color: "#fff", // Change the color to your desired color
                      },
                    }}
                    color="badgeColor"
                    badgeContent={0}
                    showZero
                  >
                    <FavoriteBorderIcon
                      style={{ height: "30px", width: "30px" }}
                    />
                  </Badge>
                </div>
                {isMediumScreen && (
                  <>
                    <div className="NBM-Div-Hover" onClick={handleClick}>
                      <PermIdentityIcon
                        style={{ height: "30px", width: "30px" }}
                      />
                    </div>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <div
                        className="popover-input-main"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Login />
                      </div>
                    </Popover>
                  </>
                )}
                <div
                  className="NBM-Div-Hover"
                  onClick={() => {
                    navigate("/CartDisplay");
                  }}
                >
                  <Badge
                    sx={{
                      "& .MuiBadge-badge": {
                        color: "#fff", // Change the color to your desired color
                      },
                    }}
                    color="badgeColor"
                    overlap="rectangular"
                    badgeContent={9}
                  >
                    <ShoppingCartOutlinedIcon
                      style={{ height: "30px", width: "30px" }}
                    />
                  </Badge>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SwipeableDrawer
          anchor={"left"}
          open={isOpen}
          onClose={toggleDrawer}
          onOpen={toggleDrawer}
        >
          <div
            style={{
              width: isextraSmallScreen
                ? "200px"
                : isMediumScreen
                ? "300px"
                : "350px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="drawer-menu-main">
              <div className="drawer-menu-content">
                <p
                  style={{
                    alignSelf: "center",
                    fontSize: "15px",
                    fontFamily: '"Roboto", sans-serif',
                  }}
                >
                  Menu
                </p>

                <div className="drawer-menu-cancel-icon">
                  <IconButton aria-label="delete" onClick={toggleDrawer}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
          <DrawerMenu toggleDrawer={toggleDrawer} />
        </SwipeableDrawer>
      </div>
    </div>
  );
}
