import React, { useEffect, useState } from "react";
import TopMenu from "../../TopMenu/TopMenu";
import NavBarMidContent from "./NavBarMidContent";
import { useMediaQuery } from "@mui/material";
import NavBarMobile from "../NavBar-Mobile/NavBarMobile";
import AdminAlert from "../../AdminAlert/AdminAlert";
import { useSelector } from "react-redux";
export default function NavBarDesktop() {
  const [other, setOther] = useState(false);
  const userData = useSelector((state) => state.login.data[0]) || [];
  const isLargeScreen = useMediaQuery("(min-width:1100px)");
  useEffect(() => {
    let u = localStorage.getItem("parentUser");
    if (u) setOther(true);
    else setOther(false);
  }, [userData]);
  return (
    <div style={{ borderBottom: "1px solid #ebebeb" }}>
      {isLargeScreen && (
        <>
          {other && <AdminAlert />}
          <TopMenu />
          <NavBarMidContent />
        </>
      )}
      {!isLargeScreen && (
        <>
          <NavBarMobile />
        </>
      )}
    </div>
  );
}
