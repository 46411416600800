import React, { useEffect, useState } from "react";
import { ColorButtonRed } from "../../../Themes/Theme";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
//css

import "../PopOverComponent.css";
import {
  GetUserLogin,
  LocalStorageLogin,
  logout,
} from "../../../Redux/Reducers/reducerSlices/LoginSlices/LoginSlice";
import { useNavigate } from "react-router-dom";
import { loginPopUpOpen } from "../../../Redux/Reducers/reducerSlices/componentActionsSlices/ComponentActionsSlice";
import WaveHeader from "../../Shared/WaveHeader/WaveHeader";
import { orderCleanUp } from "../../../Redux/Reducers/reducerSlices/MyOrderSlice/MyOrderSlice";

export default function Login() {
  const [loginData, SetLoginData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const dispatch = useDispatch();

  //call only state and destructure it saving loading userdata will call the redux state 2 time which is less effective
  const isLoading = useSelector((state) => state.login.isLoading);

  const userData = useSelector((state) => state.login.data[0]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userData"));
    // console.log(user);
    if (user) {
      LocalStorageLogin(user);
    }
  }, [userData]);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;

    SetLoginData({ ...loginData, [name]: value });
  };
  const handlelogout = (e) => {
    dispatch(logout());
    dispatch(orderCleanUp());
  };

  const handleLogin = (e) => {
    if (loginData?.email.length !== 0 && loginData?.password.length !== 0) {
      const { email, password } = loginData;
      dispatch(GetUserLogin({ email, password }));
    } else {
      if (!loginData.email.length) setErrors({ email: "Please add email" });
      if (!loginData.password.length)
        setErrors({ password: "Please add password" });
    }
  };

  const LoginPopOver = () => {
    return (
      <>
        <span className="popover-title">
          <WaveHeader title={"Log In to Your Account"} type={"Login"} />
          {/* <p className="popover-title-text"> Log In to Your Account </p> */}
        </span>

        <Stack direction={"column"} p={2} gap={1}>
          <input
            name="email"
            type="email"
            className="NBM-Search-Box" //NBM-ColorButtonText will be found in mid src\Components\Header\NavBar\NavBar-Desktop\NavBar.css
            placeholder="Email"
            onChange={(e) => handleChange(e)}
            value={loginData?.email}
            onClick={() => setErrors({ email: "" })}
            required
          />

          {errors.email && <p>{errors.email}</p>}
          <input
            name="password"
            type="password"
            className="NBM-Search-Box" //NBM-ColorButtonText will be found in mid src\Components\Header\NavBar\NavBar-Desktop\NavBar.css
            placeholder="Password"
            onChange={(e) => handleChange(e)}
            value={loginData?.password}
            onClick={() => setErrors({ password: "" })}
            required
          />
          {errors.password && <p>{errors.password}</p>}
          <ColorButtonRed
            type="submit"
            style={{ marginTop: "20px" }}
            className="NBM-ColorButtonText" //NBM-ColorButtonText will be found in mid src\Components\Header\NavBar\NavBar-Desktop\NavBar.css
            onClick={() => {
              handleLogin();
            }}
          >
            {isLoading ? <CircularProgress sx={{ width: "30px" }} /> : "Login"}
          </ColorButtonRed>

          {/* navbartext will be found in src\Components\Header\TopMenu\TopMenu.css */}
          <p
            className="navbartext"
            onClick={() => {
              navigate("/Register");
              dispatch(loginPopUpOpen(false));
            }}
          >
            Create an Account
          </p>
          <p className="navbartext">Forgot Password</p>
        </Stack>
      </>
    );
  };

  return !userData ? (
    LoginPopOver()
  ) : (
    <>
      <span className="popover-title-details">
        <p className="popover-title-text">
          {" "}
          {userData?.firstName + " " + userData?.lastName}{" "}
        </p>
        <p className="popover-title-email"> {userData?.email}</p>
      </span>
      <Stack direction={"column"} mt={2} mb={2}>
        <p
          className="popover-details-links"
          onClick={() => {
            navigate("/UpdateUser");
          }}
        >
          Edit Profile
        </p>
        <p
          className="popover-details-links"
          onClick={() => {
            navigate("MyOrders");
          }}
        >
          My Orders
        </p>
        <div className="popover-details-logout">
          <ColorButtonRed
            fullWidth
            style={{ margin: "20px" }}
            className="NBM-ColorButtonText" //NBM-ColorButtonText will be found in mid src\Components\Header\NavBar\NavBar-Desktop\NavBar.css
            onClick={() => {
              handlelogout();
            }}
          >
            Logout
          </ColorButtonRed>
        </div>
      </Stack>
    </>
  );
}

// bodyType: "Hatchback";
// cc: "1598";
// fuelType: "Petrol";
// manufacturer: "ALFA ROMEO";
// model: "147 (937_)";
// series: "1.6 16V T.SPARK ECO (937.AXA1A, 937.BXA1A)";
// year: "2002";
// {
//   "manufacturer": "BMW",
//   "model": "02 Series",
//   "year": "1967",
//   "series": "1600 Ti",
//   "cc": "1573",
//   "fuelType": "Petrol",
//   "bodyType": "Saloon"
// }
