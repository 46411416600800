import "./App.css";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { store } from "./Redux/Store/Store";
import RoutesComponent from "./Router/RoutesComponent";

function App() {
  return (
    <div>
      <Provider store={store}>
        {/* <LandingPage /> */}
        <RoutesComponent />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Provider>
    </div>
  );
}

export default App;
