import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../../../Constants/Constants";
const initialState = {
  isLoading: false,
  data: [],
  error: "",
};

export const GetChildUserDetails = createAsyncThunk(
  "ChildUser/GetChildUserDetails",
  async ({ sq, type }, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        `${BASE_URL}GetUserDetailsList?InputText=${sq}&Type=${type}`
      );
      return res.data.value;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

const AdminSlice = createSlice({
  name: "MyOrders",
  initialState,

  //GetChildUserDetails
  extraReducers: (builder) => {
    builder.addCase(GetChildUserDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(GetChildUserDetails.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(action.payload);
    });
    builder.addCase(GetChildUserDetails.pending, (state, action) => {
      state.isLoading = true;
    });
  },
});
export default AdminSlice.reducer;
