import React from "react";
import StripeCheckout from "./StripeCheckout";

export default function StripPaymentGateway() {
  return (
    <div>
      <StripeCheckout />
    </div>
  );
}
