import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../../../Constants/Constants";
const initialState = {
  isLoading: false,
  isLoadingOE: false,
  isLoadingPD: false,
  data: [],
  Mdata: [],
  error: "",
  productViewDetails: [],
  countObj: { partCount: 0, vehicleCount: 0 },
  model: [],
  year: [],
  series: [],
  cc: [],
  fuelType: [],
  bodyType: [],
  manufacturer: [],
  yearFrom: [],
  vehicleIDs: "",
  productDetailsWithPC: [],
  OEPartNumber: [],
  selectedMfg: "",
  isLoadingForDrawer: false,
  PartTypeByManufacturer: [],
  vehicalFinderObj: [],
};

export const GetProductDetails = createAsyncThunk(
  "Products/GetProductDetails",
  async ({ SearchInput, type, userID }, { rejectWithValue }) => {
    const encodedSearchInput = encodeURIComponent(SearchInput);
    const uid = userID === null ? 0 : userID
    try {
      // console.log(SearchInput, "abc", type);
      const res = await axios.get(
        `${BASE_URL}GetProductDetailswithEverything?SearchInput=${encodedSearchInput}&Type=${type}&UserID=${uid}`
      );
      return res.data.value;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);
export const GetPartType = createAsyncThunk(
  "Products/GetPartType",
  async ({ SearchInput }, { rejectWithValue }) => {
    // console.log(SearchInput);
    try {
      const res = await axios.get(
        `${BASE_URL}GetPartTypeByManufacturer?Manufacturer=${SearchInput}`
      );
      return res.data.value;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);
export const GetProductDetailsView = createAsyncThunk(
  "Products/GetProductDetailsView",
  async (action, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        `${BASE_URL}GetVehicleCompatibilityDetails?ProductCode=${action}`
      );
      return res.data.value;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);
export const GetManufacturerList = createAsyncThunk(
  "Products/GetManufacturerList",
  async (_, { rejectWithValue }) => {
    try {
      // console.log("calling mfug api");
      const res = await axios.get(`${BASE_URL}GetManufacturerList`);
      // console.log(res);
      return res.data.value;
    } catch (e) {
      // console.log(e);
      return rejectWithValue(e.message);
    }
  }
);
export const GetProductWithCode = createAsyncThunk(
  "Products/GetProductWithCode",
  async (
    { SearchInput, searchType = "default", userID },
    { rejectWithValue }
  ) => {
    const uid = userID === null ? 0 : userID
    try {
      // console.log(ProductCode, "calling mfug api");
      // console.log(searchType);
      const res = await axios.get(
        `${BASE_URL}GetProductDetails?ProductCode=${SearchInput}&UserID=${uid}`
      );
      // console.log(res);
      return { data: res.data.value, searchType: searchType };
    } catch (e) {
      // console.log(e);
      return rejectWithValue(e.message);
    }
  }
);
export const getProductsCount = createAsyncThunk(
  "Products/getProductsCount",
  async (action, { rejectWithValue }) => {
    try {
      // console.log(ProductCode, "calling mfug api");

      const res = await axios.post(
        `${BASE_URL}GetVehicleFinderDetailsCount`,
        action
      );
      // console.log(res);
      return res.data.value;
    } catch (e) {
      // console.log(e);
      return rejectWithValue(e.message);
    }
  }
);
export const getFilterDataX = createAsyncThunk(
  "Products/getFilterDataX",
  async ({ action = {}, type = "" }, { rejectWithValue }) => {
    // console.log(action, type);
    try {
      // console.log(ProductCode, "calling mfug api");

      const res = await axios.post(
        `${BASE_URL}GetVehicleFinderDetails`,
        action
      );
      // console.log(res);
      return { data: res.data.value, type: type };
    } catch (e) {
      // console.log(e);
      return rejectWithValue(e.message);
    }
  }
);
export const getVehicleId = createAsyncThunk(
  "Products/getVehicleId",
  async (action, { rejectWithValue }) => {
    // console.log(action, "getvehicle ID");
    try {
      // console.log(ProductCode, "calling mfug api");

      const res = await axios.post(`${BASE_URL}GetVehicleIDS`, action);
      // console.log(res);
      return { data: res.data.value };
    } catch (e) {
      // console.log(e);
      return rejectWithValue(e.message);
    }
  }
);
export const getOEPartNumber = createAsyncThunk(
  "Products/getOEPartNumber",
  async ({ PC }, { rejectWithValue }) => {
    // console.log(PC, "PC");
    try {
      // console.log(ProductCode, "calling mfug api");

      const res = await axios.get(
        `${BASE_URL}GetOEPartNumberDetails?ProductCode=${PC}`
      );
      // console.log(res);
      return { data: res.data.value };
    } catch (e) {
      // console.log(e);
      return rejectWithValue(e.message);
    }
  }
);

const ProductDetailsSlice = createSlice({
  name: "ProductDetails",
  initialState,
  reducers: {
    // logout: (state) => {
    //   state.data = [];
    //   toast.success("Logged Out successfully!");
    // },
    resetState: (state) => {
      state.productViewDetails = [];
      state.OEPartNumber = [];
    },
    setselectedMfg: (state, action) => {
      state.selectedMfg = action.payload;
    },
    selectedMfgCleanUp: (state) => {
      state.PartTypeByManufacturer = [];
    },
    setvehicleFinderObj: (state, action) => {
      state.vehicalFinderObj = action.payload;
    },
    setvehicleFinderObjCleanUp: (state) => {
      state.vehicalFinderObj = [];
    },
    setdataCleanUp: (state) => {
      // console.log("data clean up called");
      state.data = [];
    },
  },
  //GetProductDetails
  extraReducers: (builder) => {
    builder.addCase(getFilterDataX.fulfilled, (state, action) => {
      state[action.payload?.type] = action.payload?.data;
    });
    builder.addCase(getFilterDataX.rejected, (state, action) => {
      state.error = action.payload;
      toast.error(action.payload?.data || "");
    });
    builder.addCase(getFilterDataX.pending, (state, action) => {});
    //count
    builder.addCase(getProductsCount.fulfilled, (state, action) => {
      state.countObj = action.payload?.length
        ? action.payload[0]
        : { partCount: 0, vehicleCount: 0 };
    });
    builder.addCase(getProductsCount.rejected, (state, action) => {
      state.error = action.payload;
      toast.error(action.payload);
    });
    builder.addCase(getProductsCount.pending, (state, action) => {});
    builder.addCase(GetProductDetailsView.fulfilled, (state, action) => {
      state.isLoadingPD = false;
      state.productViewDetails = action.payload;
    });
    builder.addCase(GetProductDetailsView.rejected, (state, action) => {
      state.isLoadingPD = false;
      state.error = action.payload;
      toast.error(action.payload);
    });
    builder.addCase(GetProductDetailsView.pending, (state, action) => {
      state.isLoadingPD = true;
    });
    builder.addCase(GetProductDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(GetProductDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      toast.error(action.payload);
    });
    builder.addCase(GetProductDetails.pending, (state, action) => {
      state.isLoading = true;
    });
    //GetManufacturerList
    builder.addCase(GetManufacturerList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.Mdata = action.payload;
    });
    builder.addCase(GetManufacturerList.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      toast.error(action.payload);
    });
    builder.addCase(GetManufacturerList.pending, (state, action) => {
      state.isLoading = true;
    });
    //GetProductWithCode
    builder.addCase(GetProductWithCode.fulfilled, (state, action) => {
      state.isLoading = false;
      // state.data = action.payload;
      if (action.payload.searchType === "PC") {
        state.productDetailsWithPC = action.payload?.data[0];
      } else {
        state.data = action.payload.data;
      }
      // console.log(action.payload.data[0]);
    });
    builder.addCase(GetProductWithCode.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      toast.error(action.payload);
    });
    builder.addCase(GetProductWithCode.pending, (state, action) => {
      state.isLoading = true;
    });
    //GetVehicleIDS
    builder.addCase(getVehicleId.fulfilled, (state, action) => {
      state.isLoading = false;
      state.vehicleIDs = action.payload?.data[0];
    });
    builder.addCase(getVehicleId.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      toast.error(action.payload);
    });
    builder.addCase(getVehicleId.pending, (state, action) => {
      state.isLoading = true;
    });
    //getOEPartNumber
    builder.addCase(getOEPartNumber.fulfilled, (state, action) => {
      state.isLoadingOE = false;
      state.OEPartNumber = action.payload?.data;
    });
    builder.addCase(getOEPartNumber.rejected, (state, action) => {
      state.isLoadingOE = false;
      state.error = action.payload;
      toast.error(action.payload);
    });
    builder.addCase(getOEPartNumber.pending, (state, action) => {
      state.isLoadingOE = true;
    });
    //PartTypeByManufacturer
    builder.addCase(GetPartType.fulfilled, (state, action) => {
      state.isLoadingForDrawer = false;
      state.PartTypeByManufacturer = action.payload;
    });
    builder.addCase(GetPartType.rejected, (state, action) => {
      state.isLoadingForDrawer = false;
      state.error = action.payload;
      toast.error(action.payload);
    });
    builder.addCase(GetPartType.pending, (state, action) => {
      state.isLoadingForDrawer = true;
    });
  },
});
// export const { logout } = ProductDetailsSlice.actions;
export default ProductDetailsSlice.reducer;
export const {
  resetState,
  setselectedMfg,
  selectedMfgCleanUp,
  setvehicleFinderObj,
  setvehicleFinderObjCleanUp,
  setdataCleanUp,
} = ProductDetailsSlice.actions;
