import React from "react";
import { navMenuesLeft } from "../../../Constants/Constants";
import "../TopMenu/TopMenu.css";
import { useNavigate } from "react-router-dom";
export default function TopMenu() {
  const navigate = useNavigate();
  return (
    <div style={{ borderBottom: "1px solid #ebebeb" }}>
      <div style={{ margin: "0 65px" }}>
        <div className="d-flex flex-row justify-content-between  p-1  ">
          <div className="row gap-2 ">
            <p className="navbartext" onClick={() => navigate("/AboutUs")}>
              About us
            </p>
            <p className="navbartext" onClick={() => navigate("/ContactUs")}>
              Contact
            </p>
            <p className="navbartext">Store Location</p>
            <p className="navbartext">Track Order</p>
          </div>
          <div className="d-flex flex-row">
            <p className="navbartext">
              Currency: <span>{"GBP"}</span>
            </p>
            <p className="navbartext">
              Language:<span>{"EN"}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
