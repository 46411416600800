import React, { useState } from "react";
import { IMAGE_BASE_URL } from "../../Constants/Constants";
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Stack,
  Dialog,
} from "@mui/material";
import ZoomBox from "../../svg/ZoomBox";
import cart from "../../svg/cart-20.svg";
import PopoverProductsDetails from "./PopoverProductsDetails";
import "./ProductsView.css";
import ProductCardHeartSvg from "../../svg/ProductCardHeartSvg";
import { useSelector, useDispatch } from "react-redux";
import StarRating from "../Shared/Star";
import { popup } from "../../Redux/Reducers/reducerSlices/componentActionsSlices/ComponentActionsSlice";
import { addToCart } from "../../Redux/Reducers/reducerSlices/AddToCartSlice/AddtoCartSlice";
import { useNavigate } from "react-router-dom";
export default function ProductCard({ product }) {
  const userData = useSelector((state) => state.login.data[0]);
  const email = userData?.email || "";
  const isOpen = useSelector((state) => state.ComponentAction.isOpenProduct);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  // const id = open ? "centered-popover" : undefined;
  const ProductInStock =
    product.stockText?.toLowerCase() === "in stock" || false;
  React.useEffect(() => {
    // console.log("calling useeffect, products card");
    if (isOpen) handleClose();
    const handleClickOutside = (event) => {
      if (anchorEl && !anchorEl.contains(event.target)) {
        handleClose();
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [anchorEl, isOpen, dispatch]);
  const handleExpandClick = (product) => {
    // console.log(product);
    product && navigate(`/PopoverProductsDetails/${product}`);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const addToCartFun = (e) => {
    e.stopPropagation();
    dispatch(addToCart({ email, product }));
  };

  // const [openPopUp, setOpenPopUp] = useState(false);

  return (
    <>
      <Card
        className="product-card-main-div"
        sx={{ maxWidth: 320, maxHeight: 500 }}
      >
        <CardActionArea
          disableRipple
          onClick={() =>
            handleExpandClick(product?.productcode || product?.productCode)
          }
        >
          <div
            style={{
              padding: "20px",
            }}
          >
            <Stack direction={"row"}>
              <CardMedia
                sx={{
                  objectFit: "scale-down",
                  paddingRight: "5px",
                  paddingLeft: "25px",
                  maxWidth: "250px",
                }}
                component="img"
                height="200px"
                width="200px"
                image={IMAGE_BASE_URL + product.pictureName}
                alt={product.name}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <Stack spacing={3}>
                  <div title="Expand" className="product-card-side-menu-box">
                    <ZoomBox />
                  </div>
                  <div className="product-card-side-menu-heart">
                    {/* <ProductCardHeartSvg /> */}
                  </div>
                </Stack>
              </div>
            </Stack>
          </div>

          <CardContent sx={{ marginTop: "-40px" }}>
            <div
              className={
                ProductInStock ? "stock-status-green" : "stock-status-red"
              }
            >
              {ProductInStock ? (
                <div className="check-mark green">&#10003;</div>
              ) : (
                <div className="check-mark red">&#10005;</div>
              )}
            </div>
            <Typography
              variant="body2"
              color="text.secondary"
              fontSize={"12px"}
            >
              Code: {product?.productcode || product?.productCode}
            </Typography>
            <Typography
              noWrap
              gutterBottom
              fontSize={"16px"}
              fontWeight={"600"}
              fontStyle={'"Roboto", sans-serif'}
              variant="p"
              component="div"
            >
              {product.partManufacturer || "_"}
            </Typography>

            {/* <Stack
              direction={"row"}
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <StarRating rating={3} />
              <Typography
                sx={{ marginTop: "10px !important" }}
                textAlign={"center"}
                variant="body2"
                color="text.secondary"
                fontSize={"12px"}
              >
                5 on 66 reviews
              </Typography>
            </Stack> */}
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Stack width={"70%"}>
                <Typography
                  fontSize={"18px"}
                  fontWeight={"700"}
                  variant="p"
                  component="div"
                >
                  {product.retailPrice}
                </Typography>

                <Typography
                  fontSize={"18px"}
                  fontWeight={"700"}
                  noWrap
                  variant="body1"
                  color="text.primary"
                >
                  Type: {product.type}
                </Typography>
              </Stack>
              <Stack direction={"column"} justifyItems={"self-end"}>
                <div
                  className="product-card-svg-wrapper"
                  onClick={(e) => addToCartFun(e)}
                >
                  <img src={cart} alt=" cart" className="product-card-cart" />
                </div>
              </Stack>
            </Stack>
            {/* <Typography variant="body2" color="text.secondary" noWrap>
              Note :{product.notes}
            </Typography> */}
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
}
