import {
  Box,
  Container,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumb from "../../Shared/Breadcrumb";
import WaveHeader from "../../Shared/WaveHeader/WaveHeader";
import { useNavigate } from "react-router-dom";
import { BootstrapInput } from "../../../Themes/Theme";
import { useDispatch, useSelector } from "react-redux";

import LoginIcon from "@mui/icons-material/Login";
import { GetLoginAsUser } from "../../../Redux/Reducers/reducerSlices/LoginSlices/LoginSlice";
import NRF from "../../../svg/no-record-found.png";
import "./Admin.css";
import { GetChildUserDetails } from "../../../Redux/Reducers/reducerSlices/AdminSlice/AdminSlice";
import Loader from "../../Shared/Loader";
const columns = [
  { id: "firstName", label: "First Name", minWidth: 100, align: "left" },
  { id: "lastName", label: "Last Name", minWidth: 150, align: "left" },
  { id: "email", label: "Email", minWidth: 150, align: "left" },
  { id: "country", label: "Country", minWidth: 100, align: "left" },
  // { id: "county", label: "County", minWidth: 170, align: "left" },
  { id: "mob", label: "Mobile No.", minWidth: 150, align: "left" },
  { id: "postcode", label: "PostCode", minWidth: 150, align: "left" },
  // { id: "tel", label: "Telephone ", minWidth: 170, align: "center" },
  { id: "town", label: "Town", minWidth: 150, align: "left" },
  // { id: "add1", label: "Address 1", minWidth: 170, align: "center" },
  // { id: "add2", label: "Address 2", minWidth: 170, align: "center" },
  { id: "login", label: "Login", minWidth: 150, align: "center" },
];
export default function AdminLogin() {
  const [type, setType] = useState("");
  const [search, setSearch] = useState("");
  const [error, setError] = useState(false);
  // table
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [loadingState, setLoadingState] = useState({});
  //redux
  const userData = useSelector((state) => state.login.data[0]) || true;
  const { data, isLoading } = useSelector((state) => state.AdminDetails);
  // console.log(data);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!userData?.isAdmin) {
      navigate("/");
    }
  }, [userData]);

  const handleChange = (e) => {
    setError(false);
    if (e.target.value === "d") {
      setError("dd");
    } else {
      // console.log(e.target.value);
      setError(false);
      setType(e.target.value);
    }
  };
  const handleSubmit = () => {
    const valid = true;

    if (!type.length || type === "" || type === "d") {
      setError("dd");
      return;
    }
    if (!search.length || search === "" || search === "d") {
      setError("ip");
      return;
    }

    valid && dispatch(GetChildUserDetails({ sq: search, type }));
  };
  const filteredData =
    data &&
    data.filter(
      (d) =>
        d?.email?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
        d?.firstName?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
        d?.lastName?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
        d?.country?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
        d?.mob?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
        d?.postcode?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
        d?.town?.toLowerCase()?.includes(searchQuery.toLowerCase())
    );
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleLogin = (uid) => {
    // console.log(uid);
    let adminCred = JSON.parse(localStorage.getItem("admin"));
    dispatch(GetLoginAsUser({ id: uid, currentUser: adminCred.email }));
  };
  return (
    <Box
      component="main"
      sx={{
        background: "#fafafa",
        padding: {
          xl: "12px 5em",
          lg: "12px 5em",
          sm: "12px 3em",
          xs: "12px 1em",
        },
        flexGrow: 1,

        zIndex: "-1",
      }}
    >
      <Stack direction={"row"}>
        <Breadcrumb text={"Admin"} />
      </Stack>
      <Container
        maxWidth={"xl"}
        style={{
          marginTop: "10px",
          height: "auto",
          background: "#fff",
          padding: "0px",
          paddingBottom: "1px",
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(228, 228, 15, 0.15) 0px 0px 0px 1px",
        }}
      >
        <div style={{ background: "#fff" }}>
          <WaveHeader title={"Admin"} type={"admin"} />
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            height={"auto"}
            m={2}
          >
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
                width: "60%",
              }}
              spacing={2}
              p={1}
              pt={2}
            >
              <Stack direction={"row"} spacing={2} width={"100%"}>
                <Grid item xs={12} sm={6}>
                  <select
                    className={
                      error === "dd" ? "admin-type-dd-error" : "admin-type-dd"
                    }
                    name="userid"
                    value={type}
                    onChange={(e) => handleChange(e)}
                    placeholder="Type"
                    label="Type"
                  >
                    <option value="d" selected>
                      Search by
                    </option>
                    <option value={1}>Email</option>
                    <option value={2}>First Name</option>
                    <option value={3}>Last Name</option>
                    <option value={4}>Mobile Number</option>
                    <option value={5}>Town</option>
                    <option value={6}>Country</option>
                    <option value={7}>Postcode</option>
                  </select>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <BootstrapInput
                    from={error === "ip" ? "red" : "#dfcfcf"}
                    name="userid"
                    fullWidth
                    placeholder="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </Grid>
              </Stack>
              <Stack direction={"column"} spacing={3}>
                {error && (
                  <p className="error-div">Please provide all the details</p>
                )}
                <button onClick={handleSubmit} className={"cart-checkout-btn"}>
                  Search
                </button>
              </Stack>
            </Grid>
            {/* Table */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              m={1}
            >
              <input
                type="text"
                placeholder="Search Order No"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{
                  padding: "10px",
                  fontSize: "16px",
                  width: "100%",
                  maxWidth: "400px",
                  marginBottom: "20px",
                }}
              />
            </Box>
            {isLoading ? (
              <Loader />
            ) : (
              <Paper
                sx={{
                  width: "100%",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {filteredData?.length ? (
                  <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead sx={{ backgroundColor: "#fff" }}>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredData
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.orderNo}
                            >
                              {columns.map((column) => {
                                const value = row[column.id];
                                if (column.id === "login") {
                                  return (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                    >
                                      <button
                                        className="download-btn"
                                        onClick={() => handleLogin(row.id)}
                                        disabled={loadingState[row.id]}
                                      >
                                        {loadingState[row.id] ? (
                                          "Loading..."
                                        ) : (
                                          <LoginIcon />
                                        )}
                                      </button>
                                    </TableCell>
                                  );
                                }

                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Box
                    sx={{
                      maxHeight: 440,
                      maxWidth: 280,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={NRF} alt="No records found" />
                  </Box>
                )}
                {filteredData?.length !== 0 && (
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={filteredData?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                )}
              </Paper>
            )}
          </Box>
        </div>
      </Container>
    </Box>
  );
}

// (@Type = 1 AND Email LIKE '%' + @InputText + '%') OR
//         (@Type = 2 AND FirstName LIKE '%' + @InputText + '%') OR
//         (@Type = 3 AND LastName LIKE '%' + @InputText + '%') OR
//         (@Type = 4 AND Mob LIKE '%' + @InputText + '%') OR
//         (@Type = 5 AND Town LIKE '%' + @InputText + '%') OR
//         (@Type = 6 AND Country LIKE '%' + @InputText + '%') OR
//         (@Type = 7 AND  LIKE '%' + @InputText + '%');
