import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL, GLOBAL_USER } from "../../../../Constants/Constants";
const initialState = {
  isLoading: false,
  data: [],
  newUser: [],
  error: "",
  updateUserLoading: false,
};

// login
export const GetUserLogin = createAsyncThunk(
  "login/GetUserLogin",
  async ({ email, password }, { rejectWithValue }) => {
    const encodedPassword = encodeURIComponent(password);
    try {
      const res = await axios.get(
        `${BASE_URL}GetUserDetails?Email=${email}&Password=${encodedPassword}`
      );
      if (res.data.statusCode === 400) toast.error("User not found.");
      if (res.data.value[0].isAdmin === true) {
        localStorage.setItem(
          "admin",
          JSON.stringify({
            email: email,
            password: password,
          })
        );
      }
      return res.data.value;
    } catch (e) {
      // console.log(e.message);
      return rejectWithValue(e.message);
    }
  }
);
export const GetLoginAsUser = createAsyncThunk(
  "login/GetLoginAsUser",
  async ({ id, currentUser }, { rejectWithValue }) => {
    // console.log(currentUser);
    const user = {
      id,
      password: GLOBAL_USER,
    };
    // console.log(email, password, "from redux ");
    try {
      const res = await axios.post(`${BASE_URL}GetUserDetailsById`, user);
      return { d: res.data.value, currentUser };
    } catch (e) {
      // console.log(e.message);
      return rejectWithValue(e.message);
    }
  }
);
export const createUser = createAsyncThunk(
  "login/createUser",
  async ({ registerData }, { rejectWithValue }) => {
    // console.log(email, password, "from redux ");
    try {
      const res = await axios.post(
        `${BASE_URL}createUserDetails`,
        registerData
      );
      return res.data;
    } catch (e) {
      // console.log(e.message);
      return rejectWithValue(e.message);
    }
  }
);
export const updateUser = createAsyncThunk(
  "login/updateUser",
  async ({ registerData }, { rejectWithValue }) => {
    // console.log(registerData, "from redux ");
    try {
      const res = await axios.post(
        `${BASE_URL}createUserDetails`,
        registerData
      );
      return res.data;
    } catch (e) {
      // console.log(e.message);
      return rejectWithValue(e.message);
    }
  }
);

const UserLoginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    logout: (state) => {
      state.data = [];
      localStorage.removeItem("userData");
      localStorage.removeItem("parentUser");
      localStorage.removeItem("admin");
      toast.success("Logged Out successfully!");
    },
    LocalStorageLogin: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    //login
    builder.addCase(GetUserLogin.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload === null) {
        toast.error("Something went wrong.");
      } else {
        state.data = action.payload;
        localStorage.setItem("userData", JSON.stringify(action.payload));
      }
    });
    builder.addCase(GetUserLogin.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(GetUserLogin.pending, (state, action) => {
      state.isLoading = true;
    });
    //login as user
    builder.addCase(GetLoginAsUser.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.d === null) {
        toast.error("Something went wrong.");
      } else {
        const { firstName, lastName } = action.payload.d[0];
        state.data = action.payload.d;
        localStorage.setItem("userData", JSON.stringify(action.payload.d));
        localStorage.setItem(
          "parentUser",
          JSON.stringify(action.payload.currentUser)
        );
        toast.success(`Logged in as ${firstName} ${lastName} successfully.`);
      }
    });
    builder.addCase(GetLoginAsUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      toast.error(action.payload);
    });
    builder.addCase(GetLoginAsUser.pending, (state, action) => {
      state.isLoading = true;
    });

    //register
    builder.addCase(createUser.fulfilled, (state, action) => {
      state.isLoading = false;
      // console.log(action.payload);
      if (action.payload.statusCode === 400) toast.error("User already exists");
      if (action.payload.statusCode === 200)
        toast.success("Registered Successfully. Welcome to ICP");
      else state.newUser = action.payload.data;
      // console.log(state.datadata);
    });
    builder.addCase(createUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      toast.error(action.payload);
    });
    builder.addCase(createUser.pending, (state, action) => {
      state.isLoading = true;
    });

    //update user
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.updateUserLoading = false;
      // console.log(action.payload);
      if (action.payload.statusCode === 400)
        toast.error("Something went wrong");
      if (action.payload.statusCode === 200)
        toast.success("Profile updated Successfully.");
      else state.newUser = action.payload.data;
      // console.log(state.datadata);
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.updateUserLoading = false;
      state.error = action.payload;
      toast.error(action.payload);
    });
    builder.addCase(updateUser.pending, (state, action) => {
      state.updateUserLoading = true;
    });
  },
});
export const { logout, LocalStorageLogin } = UserLoginSlice.actions;
export default UserLoginSlice.reducer;
