import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isOpen: false,
  loginPopUp: false,
};

const ComponentActionsSlice = createSlice({
  name: "componentAction",
  initialState,
  reducers: {
    popup: (state, action) => {
      state.isOpen = action.payload;
    },
    loginPopUpOpen: (state, action) => {
      state.loginPopUp = action.payload;
    },
  },
});
export const { popup, loginPopUpOpen } = ComponentActionsSlice.actions;
export default ComponentActionsSlice.reducer;
