import React from "react";
import "./Footer.css";
import { Grid, Stack, useTheme, useMediaQuery } from "@mui/material";
import { ColorButtonRed } from "../../Themes/Theme";
import { useNavigate } from "react-router-dom";
export default function Footer() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isextraSmallScreen = useMediaQuery("(max-width:500px)");
  const direction = !isMediumScreen ? "column" : "row";
  return (
    <div className="footer-hero">
      <div className="row">
        <div className="col-12 col-xl-4">
          <p className="footer-contactus">Contact Us</p>

          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, paddingTop: "10px" }}
          >
            <Grid display={"flex"} item xs={6}>
              <Stack
                direction={direction}
                display={"flex"}
                justifyContent={"flex-start"}
              >
                <div className="footers-all-sub-details">PHONE NUMBER</div>
                <div className="footers-all-sub-details-content">
                  01268 857 880
                </div>
                <div className="footers-all-sub-details">SKYPE NUMBER</div>
                <div className="footers-all-sub-details-content">
                  0208 123 0124
                </div>
              </Stack>
            </Grid>
            <Grid display={"flex"} item xs={6}>
              <Stack
                direction={direction}
                display={"flex"}
                justifyContent={"flex-start"}
              >
                <div className="footers-all-sub-details">Email Address</div>
                <p
                  style={{ textWrap: "wrap" }}
                  className="footers-all-sub-details-content"
                >
                  Sales@ignitioncarparts.co.uk
                </p>
              </Stack>
            </Grid>
            <Grid display={"flex"} item xs={6}>
              <Stack
                direction={direction}
                display={"flex"}
                justifyContent={"flex-start"}
              >
                <div className="footers-all-sub-details">Our Location</div>
                <div className="footers-all-sub-details-content">
                  Unit 16 Ilford Trading Est Paycocke Road BASILDON Essex SS14
                  3DR
                </div>
              </Stack>
            </Grid>
            <Grid display={"flex"} item xs={6}>
              <Stack
                direction={direction}
                display={"flex"}
                justifyContent={"flex-start"}
              >
                <div className="footers-all-sub-details">Working Hours</div>
                <div className="footers-all-sub-details-content">
                  Monday to Friday 9am to 5pm
                </div>
                <div className="footers-all-sub-details-content">
                  Closed for Lunch 12-1pm
                </div>
                <div className="footers-all-sub-details-content">
                  Closed bank holidays
                </div>
              </Stack>
            </Grid>
          </Grid>
        </div>
        <div className="col-6 col-md-3 col-xl-2">
          {/* <div className="footer-contactus">Information</div> */}

          {/* <div className="footer-all-sub-details">Delivery Information</div> */}
          {/* <div className="footer-all-sub-details">Privacy Policy</div> */}
          {/* <div className="footer-all-sub-details">Brands</div> */}
          {/* <div className="footer-all-sub-details">Returns</div> */}
          {/* <div className="footer-all-sub-details">Maps</div> */}
          <div className="footer-contactus">My Account</div>
          {/* <div className="footer-all-sub-details">Store Location</div> */}
          <div
            className="footer-all-sub-details"
            onClick={() => {
              navigate("MyOrders");
            }}
          >
            Order History
          </div>
          <div
            className="footer-all-sub-details"
            onClick={() => {
              navigate("/CartDisplay");
            }}
          >
            My Cart
          </div>
          <div
            className="footer-all-sub-details"
            onClick={() => navigate("/AboutUs")}
          >
            About Us
          </div>
        </div>
        <div className="col-6 col-md-3 col-xl-2">
          {/* <div className="footer-contactus">My Account</div> */}
          {/* <div className="footer-all-sub-details">Store Location</div> */}
          {/* <div
            className="footer-all-sub-details"
            onClick={() => {
              navigate("MyOrders");
            }}
          >
            Order History
          </div>
          <div
            className="footer-all-sub-details"
            onClick={() => {
              navigate("/CartDisplay");
            }}
          >
            My Cart
          </div>
          <div
            className="footer-all-sub-details"
            onClick={() => navigate("/AboutUs")}
          >
            About Us
          </div> */}
          {/* <div className="footer-all-sub-details">Newsletter</div> */}
          {/* <div className="footer-all-sub-details">Specials</div> */}
          {/* <div className="footer-all-sub-details">Gift Certificates</div> */}
        </div>
        <div className="col-12 col-md-6 col-xl-4">
          <div className="footer-contactus">Newsletter</div>
          <div className="footer-description">
            Enter your email address below to subscribe to our newsletter and
            keep up to date with discounts and special offers.
          </div>
          <div>
            <Stack direction={!isextraSmallScreen ? "row" : "column"}>
              <input
                className="footer-Search-Box"
                placeholder="Email Address..."
              />
              <ColorButtonRed disabled>Subscribe</ColorButtonRed>
            </Stack>
          </div>
        </div>
      </div>
    </div>
  );
}
