import React, { useEffect, useState } from "react";
import { GetMyOrders } from "../../../Redux/Reducers/reducerSlices/MyOrderSlice/MyOrderSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogTitle,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../Shared/Breadcrumb";
import PageTitles from "../../Shared/PageTitles";
import WaveHeader from "../../Shared/WaveHeader/WaveHeader";
import "./MyOrders.css";
import axios from "axios";
import { BASE_URL } from "../../../Constants/Constants";
import { toast } from "react-toastify";
import Loader from "../../Shared/Loader";
import NRF from "../../../svg/no-record-found.png";

const columns = [
  { id: "orderNo", label: "Order No", minWidth: 100, align: "center" },
  { id: "quantity", label: "Quantity", minWidth: 170, align: "center" },
  { id: "date", label: "Date", minWidth: 170, align: "center" },
  { id: "download", label: "Invoice", minWidth: 170, align: "center" },
  { id: "details", label: "Details", minWidth: 170, align: "center" },
];

export default function MyOrders() {
  const { data, isLoading, details, isLoadingForDetails } = useSelector(
    (state) => state.MyOrders
  );
  // console.log(details);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sortedOrders, setSortedOrders] = useState([]);
  const [loadingState, setLoadingState] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.login.data[0]) || [];

  const sepratedOrders = (orders) => {
    const orderObj = {};
    orders &&
      orders.forEach((order) => {
        const { orderNo, quantity } = order;
        if (orderObj[orderNo]) {
          orderObj[orderNo].quantity += quantity;
        } else {
          orderObj[orderNo] = { ...order, quantity };
        }
      });
    return Object.values(orderObj);
  };

  useEffect(() => {
    if (!userData || userData.length < 1) {
      navigate("/");
    } else {
      dispatch(GetMyOrders({ userID: userData?.id, type: 1 }));
    }
  }, [userData]);

  useEffect(() => {
    if (data) {
      setSortedOrders(sepratedOrders(data));
    }
  }, [data]);

  const mappableData =
    sortedOrders?.filter(
      (i) => i?.orderNo !== null && i?.status !== "Shopping Cart"
    ) || [];

  const filteredData = mappableData.filter((order) =>
    order.orderNo.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDownload = async (orderNo) => {
    try {
      setLoadingState((prev) => ({ ...prev, [orderNo]: true }));
      const res = await axios.get(`${BASE_URL}GeneratePdf/${orderNo}/${2}`);
      const linkSource = `data:application/pdf;base64,${res.data}`;
      const downloadLink = document.createElement("a");
      const fileName = `${orderNo}_Invoice.pdf`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      setLoadingState((prev) => ({ ...prev, [orderNo]: false }));

      toast.success("Invoice downloaded successfully..!");
    } catch (error) {
      // console.log(error);
      toast.error(
        "An error occurred during downloading Invoice. Please try again."
      );
      setLoadingState((prev) => ({ ...prev, [orderNo]: false }));
    }
  };
  const handleDetails = (orderNo) => {
    // console.log(orderNo);
    handleClickOpen();
    dispatch(GetMyOrders({ userID: orderNo, type: 2 }));
  };

  return (
    <Box
      sx={{
        background: "#fafafa",
        padding: {
          xl: "12px 5em",
          lg: "12px 5em",
          sm: "12px 3em",
          xs: "12px 1em",
        },
        zIndex: "-1",
      }}
    >
      <Stack direction={"row"}>
        <Breadcrumb text={"Home"} />
        <Breadcrumb text={"About Us"} />
      </Stack>
      <Container
        maxWidth={"xl"}
        style={{
          marginTop: "10px",
          height: "auto",
          background: "#fff",
          padding: "0px",
          paddingBottom: "1px",
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(228, 228, 15, 0.15) 0px 0px 0px 1px",
        }}
      >
        <div style={{ background: "#fff" }}>
          <WaveHeader title={"My Orders"} type={"myOrders"} />
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            height={"auto"}
            m={2}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              m={1}
            >
              <input
                type="text"
                placeholder="Search Order No"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{
                  padding: "10px",
                  fontSize: "16px",
                  width: "100%",
                  maxWidth: "400px",
                  marginBottom: "20px",
                }}
              />
            </Box>
            {isLoading ? (
              <Loader />
            ) : (
              <Paper
                sx={{
                  width: "100%",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {filteredData.length ? (
                  <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead
                        sx={{ backgroundColor: "#fff", zIndex: "11111" }}
                      >
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredData
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.orderNo}
                            >
                              {columns.map((column) => {
                                const value = row[column.id];
                                if (column.id === "download") {
                                  return (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                    >
                                      <button
                                        className="download-btn"
                                        onClick={() =>
                                          handleDownload(row.orderNo)
                                        }
                                        disabled={loadingState[row.orderNo]}
                                      >
                                        {loadingState[row.orderNo]
                                          ? "Loading..."
                                          : "Download"}
                                      </button>
                                    </TableCell>
                                  );
                                }
                                if (column.id === "details") {
                                  return (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                    >
                                      <button
                                        className="download-btn"
                                        onClick={() =>
                                          handleDetails(row.orderNo)
                                        }
                                        disabled={loadingState[row.orderNo]}
                                      >
                                        {loadingState[row.orderNo]
                                          ? "Loading..."
                                          : "View"}
                                      </button>
                                    </TableCell>
                                  );
                                }
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Box
                    sx={{
                      maxHeight: 440,
                      maxWidth: 280,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={NRF} alt="No records found" />
                  </Box>
                )}
                {filteredData.length !== 0 && (
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                )}
              </Paper>
            )}
          </Box>
        </div>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth={"800px"}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center !Important",
          }}
        >
          <DialogTitle fontSize={30} ml={4}>
            Details
          </DialogTitle>

          <Box
            sx={{
              height: "400px",
              width: "700px",
              padding: "0px 40px",
              display: "flex",
            }}
          >
            {isLoadingForDetails ? (
              <Box
                sx={{
                  width: "100%",
                  height: "60%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color={"error"} />
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    width: "5em",
                    background: "#ff00009e",
                    mb: 3,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="details-orderID">{details[0]?.orderNo}</div>
                </Box>
                <Box>
                  <Stack pl={2} spacing={3}>
                    <Typography
                      variant="h6"
                      style={{
                        borderLeft: "2px solid #ff00009e",
                        paddingLeft: "10px",
                      }}
                    >
                      Transaction ID: {details[0]?.ePDQ_AuthCode}
                    </Typography>
                    <Typography
                      variant="h6"
                      style={{
                        borderLeft: "2px solid #ff00009e",
                        paddingLeft: "10px",
                      }}
                    >
                      Shipping by : {details[0]?.shippingName}
                    </Typography>
                    <Typography
                      variant="h6"
                      style={{
                        borderLeft: "2px solid #ff00009e",
                        paddingLeft: "10px",
                      }}
                    >
                      Status : {details[0]?.status}
                    </Typography>
                    {details[0]?.trackingNo && (
                      <Typography
                        variant="h6"
                        style={{
                          borderLeft: "2px solid #ff00009e",
                          paddingLeft: "10px",
                        }}
                      >
                        Tracking No : {details[0]?.trackingNo}
                      </Typography>
                    )}
                  </Stack>
                </Box>
              </>
            )}
          </Box>
        </Dialog>
      </Container>
    </Box>
  );
}

// add1: "55, Cambridge Road";
// add2: "";
// country: "England";
// county: "Greater London";
// email: "NEWPORTIGNITIONSERVICES";
// firstName: "NEWPORT";
// id: 1386;
// lastName: "IGNITION SERVICES";
// mob: null;
// postcode: "TW4 7BT";
// tel: null;
// town: "HOUNSLOW";
// { id: "firstName", label: "First Name", minWidth: 100, align: "left" },
//   { id: "lastName", label: "Last Name", minWidth: 150, align: "left" },
//   { id: "email", label: "Email", minWidth: 150, align: "left" },
//   { id: "country", label: "Country", minWidth: 100, align: "left" },
//   // { id: "county", label: "County", minWidth: 170, align: "left" },
//   { id: "mob", label: "Mobile No.", minWidth: 150, align: "left" },
//   { id: "postcode", label: "PostCode", minWidth: 150, align: "left" },
//   // { id: "tel", label: "Telephone ", minWidth: 170, align: "center" },
//   { id: "town", label: "Town", minWidth: 150, align: "left" },
//   // { id: "add1", label: "Address 1", minWidth: 170, align: "center" },
//   // { id: "add2", label: "Address 2", minWidth: 170, align: "center" },
//   { id: "login", label: "Login", minWidth: 150, align: "center" },
