import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  CardContent,
  Stack,
  Card,
  CardHeader,
  Avatar,
  CircularProgress,
} from "@mui/material";
import Breadcrumb from "../../Shared/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import WaveHeader from "../../Shared/WaveHeader/WaveHeader";
import PPLogo from "../../../svg/PPLogo.svg";
import { toast } from "react-toastify";
import "./PayPalConfirm.css";
import {
  PaypalPaymentStatusCleanUp,
  paypalStatus,
  sendOrder,
} from "../../../Redux/Reducers/reducerSlices/ChechOutSlice/OrderSlice";
import { useDispatch, useSelector } from "react-redux";
import PPS from "../../../svg/PPS.gif";
import { clearCart } from "../../../Redux/Reducers/reducerSlices/AddToCartSlice/AddtoCartSlice";
export default function PayPalConfirmation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { PPkey } = useParams();
  const [timer, setTimer] = useState(0); // 2 minutes in seconds
  const [count, setCount] = useState(0);
  const [stopTimer, setStopTimer] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isFalied, setIsFailed] = useState(false);
  const { PaypalPaymentStatus } = useSelector((state) => state.order);
  const { checkoutProducts, totalAmount, orderID } = useSelector(
    (state) => state.order
  );
  const time = 120;
  // console.log(checkoutProducts);
  useEffect(() => {
    // console.log(PaypalPaymentStatus);
    let interval = 0;
    if (timer !== time) {
      if (!stopTimer) {
        interval = setInterval(() => {
          setTimer((prevTimer) => prevTimer + 1);
          // if (paypalStatus === "CREATED" || PaypalPaymentStatus === "") {
          // }
          if (PaypalPaymentStatus === "APPROVED") {
            setStopTimer(true);
            setIsApproved(true);
            const mappedProducts = [
              {
                executionType: 2,
                orderNo: orderID[0],
                productCode: checkoutProducts[0]?.productcode,
                quantity: checkoutProducts[0]?.quantity,
                customerID: checkoutProducts[0]?.customerID || 0,
                delivery_Name: checkoutProducts[0]?.delivery_Name,
                delivery_Add1: checkoutProducts[0]?.delivery_Add1,
                delivery_Add2: checkoutProducts[0]?.delivery_Add2,
                delivery_Town: checkoutProducts[0]?.delivery_Town,
                delivery_County: checkoutProducts[0]?.delivery_County,
                delivery_Postcode: checkoutProducts[0]?.delivery_Postcode,
                delivery_Country: checkoutProducts[0]?.delivery_Country,
                cC_Name: checkoutProducts[0]?.cC_Name,
                cC_Add1: checkoutProducts[0]?.cC_Add1,
                cC_Add2: checkoutProducts[0]?.cC_Add2,
                cC_Town: checkoutProducts[0]?.cC_Town,
                cC_County: checkoutProducts[0]?.cC_County,
                cC_Postcode: checkoutProducts[0]?.cC_Postcode,
                cC_Country: checkoutProducts[0]?.cC_Country,
                perUnitPrice: checkoutProducts[0]?.perUnitPrice,
                perUnitVAT: checkoutProducts[0]?.perUnitVAT,
                shippingBand: "",
                shippingName: checkoutProducts[0]?.courierName,
                shippingNett: checkoutProducts[0]?.shippingNett,
                shippingVAT: checkoutProducts[0]?.shippingVAT,
                status: "Shopping Cart",
                date: checkoutProducts[0]?.date,
                supplierCode: null,
                paymentMethod: "Paypal",
                note: null,
                customerOrderNo: null,
                issueNumber: 0,
                headOffice: 0,
                companyName: null,
                paidDate: new Date().toISOString(),
                ePDQ_LastTransactionType: "none",
                ePDQ_AuthCode: PPkey,
                orderTime: checkoutProducts[0]?.orderTime,
                orderedBy: "",
                surcharge: 0.0,
                handlingCharge: 0.0,
                numberSold: 0,
                trackingNo: null,
                courierName: checkoutProducts[0]?.courierName,
                courierCollectionDate: new Date().toISOString(), //temporary, value needs to be updated later
                courierCollectionDeliveryDate: new Date().toISOString(), //temporary, value needs to be updated later
                courierCollectionReference: "",
                orderType: "order",
                options: "",
                vehicleID: 0,
              },
            ];
            dispatch(sendOrder(mappedProducts));
            dispatch(PaypalPaymentStatusCleanUp());
            dispatch(clearCart());
          } else {
            if ((timer + 1) % 10 === 0) {
              dispatch(paypalStatus(PPkey));
            }
          }
        }, 1000);
      }
    }

    // Clean up the interval
    return () => clearInterval(interval);
  }, [stopTimer, dispatch, timer]);

  useEffect(() => {
    if (timer === time) {
      if (count < 1) {
        setTimer(0);
        setCount((prev) => prev + 1);
      } else {
        setStopTimer(true);
        setIsFailed(true);
        toast.error("Payment failed");
        const mappedProducts = [
          {
            executionType: 3,
            orderNo: orderID[0],
            productCode: checkoutProducts[0]?.productcode,
            quantity: checkoutProducts[0]?.quantity,
            customerID: checkoutProducts[0]?.customerID || 0,
            delivery_Name: checkoutProducts[0]?.delivery_Name,
            delivery_Add1: checkoutProducts[0]?.delivery_Add1,
            delivery_Add2: checkoutProducts[0]?.delivery_Add2,
            delivery_Town: checkoutProducts[0]?.delivery_Town,
            delivery_County: checkoutProducts[0]?.delivery_County,
            delivery_Postcode: checkoutProducts[0]?.delivery_Postcode,
            delivery_Country: checkoutProducts[0]?.delivery_Country,
            cC_Name: checkoutProducts[0]?.cC_Name,
            cC_Add1: checkoutProducts[0]?.cC_Add1,
            cC_Add2: checkoutProducts[0]?.cC_Add2,
            cC_Town: checkoutProducts[0]?.cC_Town,
            cC_County: checkoutProducts[0]?.cC_County,
            cC_Postcode: checkoutProducts[0]?.cC_Postcode,
            cC_Country: checkoutProducts[0]?.cC_Country,
            perUnitPrice: checkoutProducts[0]?.perUnitPrice,
            perUnitVAT: checkoutProducts[0]?.perUnitVAT,
            shippingBand: "",
            shippingName: checkoutProducts[0]?.courierName,
            shippingNett: checkoutProducts[0]?.shippingNett,
            shippingVAT: checkoutProducts[0]?.shippingVAT,
            status: "Shopping Cart",
            date: checkoutProducts[0]?.date,
            supplierCode: null,
            paymentMethod: "Paypal",
            note: null,
            customerOrderNo: null,
            issueNumber: 0,
            headOffice: 0,
            companyName: null,
            paidDate: new Date().toISOString(),
            ePDQ_LastTransactionType: "none",
            ePDQ_AuthCode: PPkey,
            orderTime: checkoutProducts[0]?.orderTime,
            orderedBy: "",
            surcharge: 0.0,
            handlingCharge: 0.0,
            numberSold: 0,
            trackingNo: null,
            courierName: checkoutProducts[0]?.courierName,
            courierCollectionDate: new Date().toISOString(), //temporary, value needs to be updated later
            courierCollectionDeliveryDate: new Date().toISOString(), //temporary, value needs to be updated later
            courierCollectionReference: "",
            orderType: "order",
            options: "",
            vehicleID: 0,
          },
        ];
        dispatch(sendOrder(mappedProducts));
      }
    }
  }, [timer]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <Box
      sx={{
        background: "#fafafa",
        padding: {
          xl: "12px 5em ",
          lg: "12px 5em ",
          sm: "12px 3em ",
          xs: "12px 1em ",
        },
        zIndex: "-1",
      }}
    >
      <Stack direction={"row"}>
        <Breadcrumb text={"Home"} />
        <Breadcrumb text={"Shopping Cart"} />
        <Breadcrumb text={"Paypal"} />
      </Stack>
      <Container
        maxWidth={"xl"}
        style={{
          marginTop: "10px",
          height: "auto",
          background: "#fff",
          padding: "0px",
          paddingBottom: "1px",
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(228, 228, 15, 0.15) 0px 0px 0px 1px",
        }}
      >
        <div style={{ background: "#fff" }}>
          <WaveHeader title={"Payment Confirmation..."} type={"PPPC"} />
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            height={"auto"}
            m={2}
          >
            <Card
              sx={{
                mt: 2,
                maxWidth: 945,
                width: {
                  xs: 250,
                  sm: 400,
                  lg: 600,
                  xl: 700,
                },
                height: {
                  xs: 200,
                  sm: 350,
                  lg: 500,
                  xl: 600,
                },
                boxShadow: "none",
              }}
            >
              <CardHeader
                sx={{
                  width: "100% !Important",
                  display: "flex",
                  justifyContent: "center",
                }}
                avatar={
                  <Avatar
                    sx={{
                      bgcolor: PPLogo ? "#fff" : "#fafafa",
                    }}
                    src={PPLogo}
                    aria-label="recipe"
                  >
                    PP
                  </Avatar>
                }
              />

              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  borderRadius: 2,
                  // borderBottom: "2px solid #0000001f",
                }}
              >
                {!isFalied && !isApproved && <h1>Awaiting Confirmation</h1>}
                {isFalied && <h1>Payment Failed</h1>}
              </CardContent>
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                {isApproved ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h1>Payment Comfirmed!!! </h1>
                    <h4>Transaction ID: {PPkey} </h4>
                    <img src={PPS} style={{ height: 200, width: 200 }} />
                    <button
                      // disabled={cartProducts.length ? false : true}
                      onClick={() => {
                        navigate("/MyOrders");
                      }}
                      className={"cart-checkout-btn"}
                    >
                      My Orders
                    </button>
                  </Box>
                ) : (
                  <Box sx={{ position: "relative", display: "inline-flex" }}>
                    <CircularProgress
                      variant="determinate"
                      value={(timer / time) * 100}
                      size={300}
                      style={{ color: "#e52727" }}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <p style={{ fontSize: "80px" }}>{formatTime(timer)}</p>
                    </Box>
                  </Box>
                )}
              </CardContent>
            </Card>
          </Box>
        </div>
      </Container>
    </Box>
  );
}
